import { NavigationMenuGroup, NavigationMenuGroupType, NavigationMenuLink } from '../models';
import { VIEWS_CONFIG } from './views-config';

const GN_PATH = 'gamenet';
const ADMIN_PATH = 'admin';

const _constructGNPath = function (viewPath: string): string {
  return _constructPath(GN_PATH, viewPath);
};

const _constructAdminPath = function (viewPath: string): string {
  return _constructPath(ADMIN_PATH, viewPath);
};

const _constructPath = function (modulePath: string, viewPath: string): string {
  return `/app/${modulePath}/${viewPath}`;
};

const GN_MENU_GROUPS = [
  new NavigationMenuGroup({
    type: NavigationMenuGroupType.EMPTY,
    links: [
      new NavigationMenuLink({
        name: 'Modules',
        uri: _constructGNPath(VIEWS_CONFIG.GN.EXERCISE.OVERVIEW_LIST.path),
        permissions: VIEWS_CONFIG.GN.EXERCISE.OVERVIEW_LIST.permissions,
        prerequisites: VIEWS_CONFIG.GN.EXERCISE.OVERVIEW_LIST.prerequisites,
        isDefault: true,
      }),
    ],
  }),
  new NavigationMenuGroup({
    type: NavigationMenuGroupType.SCORING,
    links: [
      new NavigationMenuLink({
        name: 'Advanced Scoring Log',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SCORING.ADVANCED_SCORING_LOG.path),
        permissions: VIEWS_CONFIG.GN.SCORING.ADVANCED_SCORING_LOG.permissions,
        prerequisites: VIEWS_CONFIG.GN.SCORING.ADVANCED_SCORING_LOG.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Scoring Log',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SCORING.SCORING_LOG.path),
        permissions: VIEWS_CONFIG.GN.SCORING.SCORING_LOG.permissions,
        prerequisites: VIEWS_CONFIG.GN.SCORING.SCORING_LOG.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Team Scoring',
        individualAssessmentName: 'User Scoring',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SCORING.TEAM_SCORING.path),
        permissions: VIEWS_CONFIG.GN.SCORING.TEAM_SCORING.permissions,
        prerequisites: VIEWS_CONFIG.GN.SCORING.TEAM_SCORING.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'User Scoring',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SCORING.USER_SCORING.path),
        permissions: VIEWS_CONFIG.GN.SCORING.USER_SCORING.permissions,
        prerequisites: VIEWS_CONFIG.GN.SCORING.USER_SCORING.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Summary Timeline',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SCORING.SUMMARY_TIMELINE.path),
        permissions: VIEWS_CONFIG.GN.SCORING.SUMMARY_TIMELINE.permissions,
        prerequisites: VIEWS_CONFIG.GN.SCORING.SUMMARY_TIMELINE.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Team Timeline',
        individualAssessmentName: 'User Timeline',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SCORING.TEAM_TIMELINE.path),
        permissions: VIEWS_CONFIG.GN.SCORING.TEAM_TIMELINE.permissions,
        prerequisites: VIEWS_CONFIG.GN.SCORING.TEAM_TIMELINE.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Summary Timeline',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SCORING.SUMMARY_TIMELINE.path + '-beta'),
        permissions: VIEWS_CONFIG.GN.SCORING.SUMMARY_TIMELINE.permissions,
        prerequisites: VIEWS_CONFIG.GN.BETA.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Team Timeline',
        individualAssessmentName: 'User Timeline',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SCORING.TEAM_TIMELINE.path + '-beta'),
        permissions: VIEWS_CONFIG.GN.SCORING.TEAM_TIMELINE.permissions,
        prerequisites: VIEWS_CONFIG.GN.BETA.prerequisites,
      }),
    ],
  }),
  new NavigationMenuGroup({
    type: NavigationMenuGroupType.REPORTING,
    links: [
      new NavigationMenuLink({
        name: 'Incident Report',
        uri: _constructGNPath(VIEWS_CONFIG.GN.REPORTING.INCIDENT_REPORT.path),
        permissions: VIEWS_CONFIG.GN.REPORTING.INCIDENT_REPORT.permissions,
        prerequisites: VIEWS_CONFIG.GN.REPORTING.INCIDENT_REPORT.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Situation Report',
        uri: _constructGNPath(VIEWS_CONFIG.GN.REPORTING.SITUATION_REPORT.path),
        permissions: VIEWS_CONFIG.GN.REPORTING.SITUATION_REPORT.permissions,
        prerequisites: VIEWS_CONFIG.GN.REPORTING.SITUATION_REPORT.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Attack Campaign',
        uri: _constructGNPath(VIEWS_CONFIG.GN.REPORTING.ATTACK_CAMPAIGN.path),
        permissions: VIEWS_CONFIG.GN.REPORTING.ATTACK_CAMPAIGN.permissions,
        prerequisites: VIEWS_CONFIG.GN.REPORTING.ATTACK_CAMPAIGN.prerequisites,
      }),
    ],
  }),
  new NavigationMenuGroup({
    type: NavigationMenuGroupType.JUDGING,
    links: [
      new NavigationMenuLink({
        name: 'Attack Reports',
        uri: _constructGNPath(VIEWS_CONFIG.GN.JUDGING.ATTACK_REPORTS.path),
        permissions: VIEWS_CONFIG.GN.JUDGING.ATTACK_REPORTS.permissions,
        prerequisites: VIEWS_CONFIG.GN.JUDGING.ATTACK_REPORTS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Incident Reports',
        uri: _constructGNPath(VIEWS_CONFIG.GN.JUDGING.INCIDENT_REPORTS.path),
        permissions: VIEWS_CONFIG.GN.JUDGING.INCIDENT_REPORTS.permissions,
        prerequisites: VIEWS_CONFIG.GN.JUDGING.INCIDENT_REPORTS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Situation Reports',
        uri: _constructGNPath(VIEWS_CONFIG.GN.JUDGING.SITUATION_REPORTS.path),
        permissions: VIEWS_CONFIG.GN.JUDGING.SITUATION_REPORTS.permissions,
        prerequisites: VIEWS_CONFIG.GN.JUDGING.SITUATION_REPORTS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Task reports',
        uri: _constructGNPath(VIEWS_CONFIG.GN.JUDGING.TASK_REPORTS.path),
        permissions: VIEWS_CONFIG.GN.JUDGING.TASK_REPORTS.permissions,
        prerequisites: VIEWS_CONFIG.GN.JUDGING.TASK_REPORTS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Special Scoring',
        uri: _constructGNPath(VIEWS_CONFIG.GN.JUDGING.SPECIAL_SCORING.path),
        permissions: VIEWS_CONFIG.GN.JUDGING.SPECIAL_SCORING.permissions,
        prerequisites: VIEWS_CONFIG.GN.JUDGING.SPECIAL_SCORING.prerequisites,
      }),
    ],
  }),
  new NavigationMenuGroup({
    type: NavigationMenuGroupType.SITUATIONAL_AWARENESS,
    links: [
      new NavigationMenuLink({
        name: 'Team Status',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TEAM_STATUS.path),
        permissions: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TEAM_STATUS.permissions,
        prerequisites: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TEAM_STATUS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Segments Status',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.SEGMENTS_STATUS.path),
        permissions: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.SEGMENTS_STATUS.permissions,
        prerequisites: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.SEGMENTS_STATUS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Target Checks Status',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TARGET_CHECKS_STATUS.path),
        permissions: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TARGET_CHECKS_STATUS.permissions,
        prerequisites: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TARGET_CHECKS_STATUS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Reporting Status',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.REPORTING_STATUS.path),
        permissions: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.REPORTING_STATUS.permissions,
        prerequisites: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.REPORTING_STATUS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Team Awareness',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TEAM_AWARENESS.path),
        permissions: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TEAM_AWARENESS.permissions,
        prerequisites: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.TEAM_AWARENESS.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Campaign Live',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.CAMPAIGN_LIVE.path),
        permissions: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.CAMPAIGN_LIVE.permissions,
        prerequisites: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.CAMPAIGN_LIVE.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Campaign Live (observer)',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.CAMPAIGN_LIVE__OBSERVER.path),
        permissions: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.CAMPAIGN_LIVE__OBSERVER.permissions,
        prerequisites: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.CAMPAIGN_LIVE__OBSERVER.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'News Injects',
        uri: _constructGNPath(VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.NEWS_INJECT.OVERVIEW_LIST.path),
        permissions: VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.NEWS_INJECT.OVERVIEW_LIST.permissions,
        prerequisites:
          VIEWS_CONFIG.GN.SITUATIONAL_AWARENESS.NEWS_INJECT.OVERVIEW_LIST.prerequisites,
      }),
    ],
  }),
  new NavigationMenuGroup({
    type: NavigationMenuGroupType.TASKS,
    links: [
      new NavigationMenuLink({
        name: 'Mission Board',
        uri: _constructGNPath(VIEWS_CONFIG.GN.CTF.MISSION_BOARD.path),
        permissions: VIEWS_CONFIG.GN.CTF.MISSION_BOARD.permissions,
        prerequisites: VIEWS_CONFIG.GN.CTF.MISSION_BOARD.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Tasks Status Overview',
        uri: _constructGNPath(VIEWS_CONFIG.GN.CTF.TASKS_STATUS_OVERVIEW.path),
        permissions: VIEWS_CONFIG.GN.CTF.TASKS_STATUS_OVERVIEW.permissions,
        prerequisites: VIEWS_CONFIG.GN.CTF.TASKS_STATUS_OVERVIEW.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Leaderboard',
        uri: _constructGNPath(VIEWS_CONFIG.GN.CTF.LEADER_BOARD.path),
        permissions: VIEWS_CONFIG.GN.CTF.LEADER_BOARD.permissions,
        prerequisites: VIEWS_CONFIG.GN.CTF.LEADER_BOARD.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Game Overview',
        hybridExerciseName: 'Tasks Overview',
        uri: _constructGNPath(VIEWS_CONFIG.GN.CTF.GAME_OVERVIEW.path),
        permissions: VIEWS_CONFIG.GN.CTF.GAME_OVERVIEW.permissions,
        prerequisites: VIEWS_CONFIG.GN.CTF.GAME_OVERVIEW.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Podium',
        uri: _constructGNPath(VIEWS_CONFIG.GN.CTF.PODIUM.path),
        permissions: VIEWS_CONFIG.GN.CTF.PODIUM.permissions,
        prerequisites: VIEWS_CONFIG.GN.CTF.PODIUM.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Podium (observer)',
        uri: _constructGNPath(VIEWS_CONFIG.GN.CTF.PODIUM__OBSERVER.path),
        permissions: VIEWS_CONFIG.GN.CTF.PODIUM__OBSERVER.permissions,
        prerequisites: VIEWS_CONFIG.GN.CTF.PODIUM__OBSERVER.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'Report',
        uri: _constructGNPath(VIEWS_CONFIG.GN.CTF.ASSESSMENT_REPORT.path),
        permissions: VIEWS_CONFIG.GN.CTF.ASSESSMENT_REPORT.permissions,
        prerequisites: VIEWS_CONFIG.GN.CTF.ASSESSMENT_REPORT.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'NICE summary',
        uri: _constructGNPath(VIEWS_CONFIG.GN.CTF.NICE_SUMMARY.path),
        permissions: VIEWS_CONFIG.GN.CTF.NICE_SUMMARY.permissions,
        prerequisites: VIEWS_CONFIG.GN.CTF.NICE_SUMMARY.prerequisites,
      }),
    ],
  }),
];

const ADMIN_MENU_GROUPS = [
  new NavigationMenuGroup({
    type: NavigationMenuGroupType.EMPTY,
    links: [
      new NavigationMenuLink({
        name: 'Settings',
        uri: _constructAdminPath(VIEWS_CONFIG.ADMIN.SETTINGS.path),
        permissions: VIEWS_CONFIG.ADMIN.SETTINGS.permissions,
      }),
      new NavigationMenuLink({
        name: 'Product Key',
        uri: _constructAdminPath(VIEWS_CONFIG.ADMIN.PRODUCT_KEY.path),
        permissions: VIEWS_CONFIG.ADMIN.PRODUCT_KEY.permissions,
      }),
      new NavigationMenuLink({
        name: 'Users',
        uri: _constructAdminPath(VIEWS_CONFIG.ADMIN.USERS.path),
        permissions: VIEWS_CONFIG.ADMIN.USERS.permissions,
      }),
      new NavigationMenuLink({
        name: 'Integrations',
        uri: _constructAdminPath(VIEWS_CONFIG.ADMIN.INTEGRATIONS.path),
        permissions: VIEWS_CONFIG.ADMIN.INTEGRATIONS.permissions,
      }),
      new NavigationMenuLink({
        name: 'Services Health Overview',
        uri: _constructAdminPath(VIEWS_CONFIG.ADMIN.SERVICES_HEALTH_OVERVIEW.path),
        permissions: VIEWS_CONFIG.ADMIN.SERVICES_HEALTH_OVERVIEW.permissions,
      }),
      new NavigationMenuLink({
        name: 'GMA Overview',
        uri: _constructAdminPath(VIEWS_CONFIG.ADMIN.GMA_OVERVIEW.path),
        permissions: VIEWS_CONFIG.ADMIN.GMA_OVERVIEW.permissions,
        prerequisites: VIEWS_CONFIG.ADMIN.GMA_OVERVIEW.prerequisites,
      }),
      new NavigationMenuLink({
        name: 'File Manager',
        uri: _constructAdminPath(VIEWS_CONFIG.ADMIN.FILE_MANAGER.path),
        permissions: VIEWS_CONFIG.ADMIN.FILE_MANAGER.permissions,
      }),
      new NavigationMenuLink({
        name: 'Custom variables',
        uri: _constructAdminPath(VIEWS_CONFIG.ADMIN.CUSTOM_VARIABLES.path),
        permissions: VIEWS_CONFIG.ADMIN.CUSTOM_VARIABLES.permissions,
      }),
    ],
  }),
];

export const NAVIGATION_MENU_CONFIG = {
  gnMenuGroups: GN_MENU_GROUPS,
  adminMenuGroups: ADMIN_MENU_GROUPS,
};
