import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxCommonsModule } from '@cybexer/ngx-commons';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IsaDirectivesModule } from '../../directives';
import { IsaPipesModule } from '../../pipes';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { BaseLayoutComponent } from './base-layout.component';
import { MenuGroupComponent } from './navigation-menu/menu-group/menu-group.component';
import { MenuSectionComponent } from './navigation-menu/menu-section/menu-section.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { IsaNotificationsComponent } from './notifications/isa-notifications.component';
import { UserRedirectDialogComponent } from './user-redirect-dialog/user-redirect-dialog.component';

@NgModule({
  declarations: [
    MenuGroupComponent,
    MenuSectionComponent,
    NavigationMenuComponent,
    BaseLayoutComponent,
    IsaNotificationsComponent,
    UserRedirectDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatListModule,
    MatExpansionModule,
    IsaPipesModule,
    SharedComponentsModule,
    NgxCommonsModule,
    IsaDirectivesModule,
    InfiniteScrollModule,
    MatTooltipModule,
  ],
})
export class BaseLayoutModule {}
