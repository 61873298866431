<footer>
  <mat-toolbar class="isa-footer">
    <span class="isa-footer-info"></span>
    <div class="isa-version">
      <cybexer-popover class="isa-version-data" [disable]="!isAdmin || !buildCommitMeta">
        <div label class="version-label">{{ version }}</div>
        <div class="popover-container">
          <div class="title">Build commit meta</div>
          <div class="disable-uppercase commit-data">
            <i class="fa-solid fa-code-branch"></i>{{ buildCommitMeta?.branch }}
          </div>
          <div class="disable-uppercase commit-data">
            <i class="fa-solid fa-code-commit"></i>{{ buildCommitMeta?.id }} /
            {{ buildCommitMeta?.shortId }}
          </div>
        </div>
      </cybexer-popover>
    </div>
    <span class="logo-text">INTEGRATED SCORING AND AWARENESS</span>
    <img class="isa-logo" src="../../../../assets/img/isa-logo.svg" />
    @if (customLabelSrc) {
      <img class="custom-label" [src]="customLabelSrc | isaSafeUrl" />
    } @else {
      <span class="logo-text">POWERED BY CYBEXER TECHNOLOGIES</span>
      <img class="cybexer-logo" src="./../../../../assets/img/cybexer-logo.png" />
    }
  </mat-toolbar>
</footer>
