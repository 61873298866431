@if (user$ | async; as user) {
  <cybexer-widget-header [title]="'User details'"></cybexer-widget-header>
  <cybexer-widget-body>
    <mat-card id="user-details">
      <div class="isa-details-box">
        <div class="row"><span class="label">Username</span> {{ user.username }}</div>
        <div class="row"><span class="label">Full name</span> {{ user.fullName }}</div>
        <div class="row"><span class="label">Roles</span> {{ displayRoles(user) }}</div>
        <div class="row">
          <span class="label">Registered</span> {{ user.registered | isaDate: 'medium' }}
        </div>
        <div class="row">
          <span class="label">Password</span>
          <button
            mat-button
            (click)="openPasswordChangeDialog(user.username)"
            data-testid="pswChangeBtn"
          >
            <i class="fas fa-key mat-button-fa" aria-hidden="true"></i>Change
          </button>
        </div>
      </div>
    </mat-card>
    <div class="roles-title">Roles</div>
  </cybexer-widget-body>
  <isa-role-assignment-table
    [selectedRoles]="user.roles"
    (onAssign)="onRoleAssign($event, user.username)"
  >
  </isa-role-assignment-table>
}
