<ng-container>
  @if (!(filter$ | async).horizontalView) {
    <div class="vertical-charts-container">
      <div class="vertical-chart-wrapper">
        <canvas
          baseChart
          #totalScoreChart="base-chart"
          [datasets]="barChartData"
          [labels]="chartLabels"
          [options]="barChartOptions"
        >
        </canvas>
      </div>
      <div class="score-split">Score Split</div>
      <div class="vertical-chart-wrapper">
        <canvas
          baseChart
          #verticalStackedChart="base-chart"
          [datasets]="stackedChartData"
          [labels]="chartLabels"
          [options]="verticalStackedChartOptions"
        >
        </canvas>
      </div>
    </div>
  }
  @if ((filter$ | async).horizontalView) {
    <div class="horizontal-chart-wrapper" [style.height.px]="chartHeight">
      <canvas
        baseChart
        #horizontalStackedChart="base-chart"
        [datasets]="stackedChartData"
        [labels]="chartLabels"
        [options]="horizontalStackedChartOptions"
      >
      </canvas>
    </div>
  }
</ng-container>
