import { BaseModel } from './base.model';

export class Preference extends BaseModel implements IPreference {
  defaultExerciseId: string;
  defaultTeamId?: string;
  dateFormat?: string;
  defaultListSize: number;
  isLightTheme: boolean;

  constructor(data?) {
    super(data);
  }
}

export interface IPreference {
  defaultExerciseId?: string;
  defaultTeamId?: string;
  dateFormat?: string;
  defaultListSize?: number;
  isLightTheme?: boolean;
}
