import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { AbsoluteValuePipe } from './abs.pipe';
import { DatePipe } from './date.pipe';
import { DurationPipe } from './duration.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FixedNumberPipe } from './fixed-number.pipe';
import { IncidentTypeNamePipe } from './gamenet/incident-type.pipe';
import { NameFilterPipe } from './gamenet/name-filter.pipe';
import { NiceDescriptionPipe } from './gamenet/nice-description-pipe';
import { ObjectiveCampaignPhasePipe } from './gamenet/objective-campaign-phase.pipe';
import { ObjectiveContainerNamePipe } from './gamenet/objective-container-name.pipe';
import { ObjectiveNamePipe } from './gamenet/objective-name.pipe';
import { TargetNamePipe } from './gamenet/target-name.pipe';
import { TaskCategoryIconPipe } from './gamenet/task-category-icon.pipe';
import { TaskCategoryNamePipe } from './gamenet/task-category-name.pipe';
import { TaskStatusTextPipe } from './gamenet/task-status-text.pipe';
import { TeamNamePipe } from './gamenet/team-name.pipe';
import { IntegrationEventTypeNamePipe } from './integration-event-type-name.pipe';
import { PlaceholderPipe } from './placeholder.pipe';
import { ReplaceUnderscoresPipe } from './replace-underscores.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { StripHtmlPipe } from './strip-html.pipe';

@NgModule({
  imports: [SharedModule],
  declarations: [
    IncidentTypeNamePipe,
    NameFilterPipe,
    TargetNamePipe,
    ObjectiveContainerNamePipe,
    ObjectiveNamePipe,
    ObjectiveCampaignPhasePipe,
    TeamNamePipe,
    DatePipe,
    ReplaceUnderscoresPipe,
    StripHtmlPipe,
    PlaceholderPipe,
    FileSizePipe,
    IntegrationEventTypeNamePipe,
    DurationPipe,
    SafeUrlPipe,
    TaskCategoryNamePipe,
    TaskCategoryIconPipe,
    AbsoluteValuePipe,
    NiceDescriptionPipe,
    FixedNumberPipe,
    TaskStatusTextPipe,
  ],
  exports: [
    IncidentTypeNamePipe,
    NameFilterPipe,
    TargetNamePipe,
    ObjectiveContainerNamePipe,
    ObjectiveNamePipe,
    ObjectiveCampaignPhasePipe,
    TeamNamePipe,
    DatePipe,
    ReplaceUnderscoresPipe,
    StripHtmlPipe,
    PlaceholderPipe,
    FileSizePipe,
    IntegrationEventTypeNamePipe,
    DurationPipe,
    SafeUrlPipe,
    TaskCategoryNamePipe,
    TaskCategoryIconPipe,
    AbsoluteValuePipe,
    NiceDescriptionPipe,
    FixedNumberPipe,
    TaskStatusTextPipe,
  ],
  providers: [
    DatePipe,
    ObjectiveCampaignPhasePipe,
    ObjectiveNamePipe,
    ObjectiveContainerNamePipe,
    TargetNamePipe,
    IncidentTypeNamePipe,
    ReplaceUnderscoresPipe,
    PlaceholderPipe,
    FileSizePipe,
    IntegrationEventTypeNamePipe,
    DurationPipe,
    SafeUrlPipe,
    TeamNamePipe,
    TaskCategoryNamePipe,
    TaskCategoryIconPipe,
    NiceDescriptionPipe,
    FixedNumberPipe,
  ],
})
export class IsaPipesModule {}
