import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IncidentReportConfirmation,
  IncidentReportFilterResult,
  IncidentReportsData,
  ReportDetails,
  ReportFilter,
} from '../../models';
import { ServiceUtil } from '../../shared';
import { ReportsService } from './reports.service';

@Injectable()
export class IncidentReportsService implements ReportsService<IncidentReportsData> {
  private static BASE_API_URL = 'api/widget/incident-reports';

  constructor(private http: HttpClient) {}

  getData(exerciseId: string, filter: ReportFilter): Observable<IncidentReportFilterResult> {
    return this.http
      .get<IncidentReportFilterResult>(
        `${IncidentReportsService.BASE_API_URL}/exercise/${exerciseId}`,
        {
          params: ServiceUtil.createHttpParamsFromObj(filter),
        }
      )
      .pipe(map((data) => new IncidentReportFilterResult(data)));
  }

  createIncidentReportConfirmation(
    exerciseId: string,
    confirmation: IncidentReportConfirmation
  ): Observable<boolean> {
    return this.http
      .post(
        `${IncidentReportsService.BASE_API_URL}/exercise/${exerciseId}/confirmation`,
        confirmation
      )
      .pipe(map(() => true));
  }

  createMultipleIncidentReportConfirmations(
    exerciseId: string,
    confirmations: IncidentReportConfirmation[]
  ): Observable<boolean> {
    return this.http
      .post(
        `${IncidentReportsService.BASE_API_URL}/exercise/${exerciseId}/confirmation/batch`,
        confirmations
      )
      .pipe(map(() => true));
  }

  getReportDetails(exerciseId: string, reportId: string): Observable<ReportDetails> {
    return this.http
      .get<ReportDetails>(
        `${IncidentReportsService.BASE_API_URL}/exercise/${exerciseId}/report/${reportId}/details`
      )
      .pipe(map((data) => new ReportDetails(data)));
  }
}
