<cybexer-widget-header [title]="'Modules'" [inline]="true">
  <mat-slide-toggle labelPosition="after" [(ngModel)]="showOnlyRunning"> Running</mat-slide-toggle>
  <mat-form-field>
    <mat-label><i class="fa fa-search"></i> Search</mat-label>
    <input autocomplete="off" matInput [(ngModel)]="searchText" />
  </mat-form-field>
  <div class="spacer"></div>
  @if (currentUser?.isAdmin) {
    <button mat-raised-button color="primary" (click)="createExerciseGroup()">
      <i class="fas fa-plus-circle" aria-hidden="true"></i>New Module Group
    </button>

    <button [routerLink]="['/app/gamenet/exercise-definition']" mat-raised-button color="primary">
      <i class="fas fa-plus-circle" aria-hidden="true"></i>New Module
    </button>
  }
</cybexer-widget-header>
<cybexer-widget-body>
  @if (exerciseGroups) {
    @for (group of filteredGroups; track group.name; let i = $index) {
      @if (
        (isModuleGroupEditingEnabled && selectedGroupIndex === i) || !isModuleGroupEditingEnabled
      ) {
        <isa-exercise-group
          [group]="group"
          (removeGroupEvent)="removeExerciseGroup(i)"
          (saveGroupEvent)="saveExerciseGroup(i)"
          (editGroupEvent)="toggleModuleGroupEditing(i, $event)"
          (removeExerciseEvent)="removeExerciseFromGroup($event)"
        ></isa-exercise-group>
      }
    }
  }

  @if (isModuleGroupEditingEnabled) {
    <mat-label>Click on module to add to group</mat-label>
  }

  <cybexer-grid [itemSize]="'small'">
    @for (exercise of filteredExercises; track exercise.status; let i = $index) {
      <div class="container">
        <isa-exercise-card
          [exercise]="exercise"
          [isInGroup]="false"
          [isSelected]="exercise?.id === currentExercise?.id"
          [findExerciseOverviewById]="findExerciseOverviewById"
        ></isa-exercise-card>
        @if (isModuleGroupEditingEnabled) {
          <div class="overlay" (click)="addExerciseToGroup(i)">
            <div class="circle">
              <div class="cross"></div>
            </div>
          </div>
        }
      </div>
    }
  </cybexer-grid>

  @if (isModuleGroupEditingEnabled && ungroupedExercises.length === 0 && !loading) {
    <cybexer-no-data [message]="'No more compatible exercises'"></cybexer-no-data>
  }
</cybexer-widget-body>
@if (loading) {
  <cybexer-progress-spinner></cybexer-progress-spinner>
}
