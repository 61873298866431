import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private isSidebarOpen = new BehaviorSubject<boolean>(true);

  setSidebarStatus(isOpen: boolean) {
    this.isSidebarOpen.next(isOpen);
  }

  getSidebarStatus() {
    return this.isSidebarOpen.getValue();
  }
}
