import { BaseModel } from '../shared/base.model';

export class IncidentType extends BaseModel {
  name: string;
  description: string;

  constructor(data?) {
    super(data);

    // TODO: remove when backend will provide description
    switch (this.name) {
      case 'ONLINE': {
        this.description = 'Target Check state changed to online';
        break;
      }
      case 'OFFLINE': {
        this.description = 'Target Check state changed to offline';
        break;
      }
      case 'COMPROMISED': {
        this.description = 'Target Check state changed to compromised';
        break;
      }
      case 'NOT_COMPROMISED': {
        this.description = 'Target Check state changed to not compromised';
        break;
      }
      default: {
        break;
      }
    }
  }
}
