<div id="role-assignment-dialog">
  <h2 matDialogTitle>Edit user role ({{ role.code }})</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content class="dialog-content">
      <mat-slide-toggle class="switch-container" formControlName="isEnabled" labelPosition="after">
        Enabled
      </mat-slide-toggle>
      <mat-slide-toggle
        class="switch-container"
        labelPosition="after"
        [disabled]="!hasAnyEnabledTargets()"
        (click)="targetFilterSwitch()"
      >
        Filter enabled targets
      </mat-slide-toggle>
      <div formGroupName="domains">
        @for (domain of getVisibleDomains(); track domain) {
          <div class="section-item" [formGroupName]="domain">
            <mat-divider></mat-divider>
            <div class="domain">
              <div>{{ getDomainName(domain) }}</div>
              @if (!isBlueDomain(domain)) {
                <mat-slide-toggle formControlName="hasWildcard" labelPosition="before">
                  Permit all
                </mat-slide-toggle>
              }
            </div>
            <mat-selection-list dense formControlName="targets">
              @for (targetSet of getTargetsByDomain(domain); track targetSet) {
                @if (targetSet.parentTargetName) {
                  <em> {{ targetSet.parentTargetName }} </em>
                }
                @for (target of targetSet.targets; track target) {
                  <mat-list-option [value]="target.id">
                    <div matLine>{{ getTargetName(target) }}</div>
                  </mat-list-option>
                }
              }
            </mat-selection-list>
          </div>
        }
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button type="submit" class="isa-button-confirm" mat-raised-button color="primary">
          Save
        </button>
        <button mat-button matDialogClose>Cancel</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
