import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConsoleAuthData,
  ConsoleConnectionParameters,
  ConsoleConnectionSource,
  ConsoleConnectionType,
} from '@cybexer/ngx-commons';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CTFTargetData, TargetStatusSummary } from '../../models';
import { SettingsService } from '../shared/settings.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualMachineService {
  private static BASE_API_URL = 'api/vm';
  private static BASE_GUACAMOLE_URL = 'api/guacamole';

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  getWebConsoleUrl(exerciseId: string, connectionId: string): Observable<string> {
    return this.settingsService.getSettings(true).pipe(
      switchMap((settings) => {
        if (settings.vlmSettings.nativeConsoleProxyEnabled) {
          const webconsoleApiUrl = `${VirtualMachineService.BASE_API_URL}/webconsole?connectionId=${connectionId}`;
          return of(`${this.getLocationOrigin()}/${webconsoleApiUrl}`.replace('http', 'ws'));
        } else {
          return this.http
            .get(`${VirtualMachineService.BASE_API_URL}/exercise/${exerciseId}/webconsole-url`, {
              responseType: 'text',
            })
            .pipe(map((url) => `${url}?connectionId=${connectionId}`));
        }
      })
    );
  }

  validateVmAccess(vmId: string, exerciseId: string, teamId: string): Observable<boolean> {
    return this.http
      .post(
        `${VirtualMachineService.BASE_API_URL}/exercise/${exerciseId}/team/${teamId}/vm-access/validate`,
        vmId
      )
      .pipe(map(() => true));
  }

  getConnectionId(vmId: string, exerciseId: string): Observable<string> {
    return this.http.post(
      `${VirtualMachineService.BASE_API_URL}/exercise/${exerciseId}/connection-id`,
      vmId,
      { responseType: 'text' }
    );
  }

  forceRefreshTargets(exerciseId: string) {
    return this.http
      .post(`${VirtualMachineService.BASE_API_URL}/force-refresh/exercise/${exerciseId}`, null)
      .pipe(map(() => true));
  }

  getGuacamoleWebsocketTunnelUrl(exerciseId: string): Observable<string> {
    return this.settingsService.getSettings(true).pipe(
      switchMap((settings) => {
        if (settings.vlmSettings.guacamoleTunnelProxyEnabled) {
          const tunnelUrl = `${VirtualMachineService.BASE_GUACAMOLE_URL}/websocket-tunnel`;
          return of(`${this.getLocationOrigin()}/${tunnelUrl}`.replace('http', 'ws'));
        } else return this.getGuacamoleTunnelUrl(exerciseId);
      })
    );
  }

  getGuacamoleHttpTunnelUrl(exerciseId: string): Observable<string> {
    return this.settingsService.getSettings(true).pipe(
      switchMap((settings) => {
        return settings.vlmSettings.guacamoleTunnelProxyEnabled
          ? of(`${VirtualMachineService.BASE_GUACAMOLE_URL}/exercise/${exerciseId}/http-tunnel`)
          : this.getGuacamoleTunnelUrl(exerciseId);
      })
    );
  }

  getGuacamoleConnectionId(
    exerciseId: string,
    consoleAuthData: ConsoleAuthData
  ): Observable<string> {
    return this.http.post(
      `${VirtualMachineService.BASE_GUACAMOLE_URL}/exercise/${exerciseId}/connection-id`,
      consoleAuthData,
      { responseType: 'text' }
    );
  }

  private getGuacamoleTunnelUrl(exerciseId: string): Observable<string> {
    return this.http.get(
      `${VirtualMachineService.BASE_GUACAMOLE_URL}/exercise/${exerciseId}/tunnel-url`,
      {
        responseType: 'text',
      }
    );
  }

  revertToCurrentSnapshot(vmId: string, exerciseId: string, targetName: string, teamId: string) {
    return this.http
      .post(
        `${VirtualMachineService.BASE_API_URL}/${vmId}/revert-to-snapshot/exercise/${exerciseId}/teams/${teamId}`,
        targetName
      )
      .pipe(map(() => true));
  }

  getLocationOrigin() {
    return location.origin;
  }

  getGuacamoleConnectionParameters(
    target: CTFTargetData | TargetStatusSummary,
    connectionType: ConsoleConnectionType,
    isTargetManagementEnabled: boolean
  ) {
    return isTargetManagementEnabled
      ? new ConsoleConnectionParameters({
          vmId: target.vmId,
          name: target.targetName,
          connectionType: connectionType,
          source: ConsoleConnectionSource.VCENTER,
        })
      : new ConsoleConnectionParameters({
          name: target.consoleData?.hostname,
          connectionType: connectionType,
          port: target.consoleData?.protocolAndPortList?.find((p) => p.protocol === connectionType)
            ?.port,
          source: ConsoleConnectionSource.DIRECT,
          username: target.consoleData?.username,
          password: target.consoleData?.password,
        });
  }
}
