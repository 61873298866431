@if (settings && settings?.gamenetEnabled && gamenetMenuGroups.length > 0) {
  <isa-menu-section
    id="gamenet-section"
    name="Cyber range"
    icon="tasks"
    [description]="exercise?.name"
    [groups]="gamenetMenuGroups"
    [isIndividualAssessment]="exercise?.isIndividualAssessment"
    [isHybridExercise]="exercise?.type === ExerciseType.HYBRID"
    [isDefaultExerciseLoading]="isDefaultExerciseLoading"
    (click)="loadDefaultExercise()"
  ></isa-menu-section>
}
@if (settings && adminMenuGroups.length > 0) {
  <isa-menu-section
    id="admin-section"
    name="Administration"
    icon="cog"
    [groups]="adminMenuGroups"
  ></isa-menu-section>
}
