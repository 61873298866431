<h2 matDialogTitle>Report status</h2>
<mat-dialog-content>
  <div class="isa-details-box">
    <div class="row">
      <label class="label" for="objective-name">Objective</label>
      <div id="objective-name" class="disable-uppercase">{{ objective.name }}</div>
    </div>
    <div class="row">
      <label class="label" for="team-name">Team</label>
      <div id="team-name" class="disable-uppercase">{{ team.teamId | isaTeamName }}</div>
    </div>
    @if (team.reportCount > 0) {
      <div class="row">
        <label class="label" for="team-name">Reports submitted</label>
        <div id="report-count" class="disable-uppercase">{{ team.reportCount }}</div>
      </div>
    }
  </div>
  @if (
    !flagRequested &&
    data.objective.type !== 'TARGET_GROUP' &&
    team.objectiveStatus !== OBJECTIVE_STATUS.COMPROMISED
  ) {
    <div class="flag-buttons-container">
      <button mat-raised-button color="primary" (click)="requestFlag(false)">Request flag</button>
      <div style="margin: 0 15px; padding-top: 8px" class="disable-uppercase">or</div>
      <button mat-raised-button color="primary" (click)="requestFlag(true)">
        SUDO Request flag
      </button>
    </div>
  }
</mat-dialog-content>

@if (flagRequested) {
  <div>
    <form [formGroup]="attackFlagForm" (ngSubmit)="onSubmit(statusReportForm, attackFlagForm)">
      @if (!flagProcessed) {
        <cybexer-progress-spinner></cybexer-progress-spinner>
      }
      @if (flagProcessed) {
        <mat-dialog-content>
          <div style="margin-top: 15px">
            Flag is planted at
            <div class="disable-uppercase flagpath">{{ flagPath }}</div>
          </div>
          <mat-form-field style="width: 100%; margin-top: 15px">
            <mat-label>Enter flag hash</mat-label>
            <input formControlName="flagContent" matInput />
          </mat-form-field>
        </mat-dialog-content>
      }
      <mat-dialog-actions>
        <div class="status-error">
          @if (!attackFlagForm.controls['flagContent'].valid && triedToSubmit) {
            <mat-error> Flag hash is required </mat-error>
          }
          @if (flagError && attackFlagForm.controls['flagContent'].valid) {
            <mat-error>
              {{ flagError }}
            </mat-error>
          }
        </div>
        <button mat-raised-button color="primary" type="submit" [disabled]="isSubmitting">
          Submit hash
        </button>
        <button mat-button matDialogClose>Cancel</button>
      </mat-dialog-actions>
    </form>
  </div>
}
@if (!flagRequested) {
  <div class="report-container">
    <form [formGroup]="statusReportForm" (ngSubmit)="onSubmit(statusReportForm, attackFlagForm)">
      <mat-dialog-content class="status-dialog-content">
        <mat-radio-group class="status-radio-group" formControlName="status">
          <mat-radio-button
            class="status-radio-button"
            [value]="objective_status.COMPROMISED"
            [attr.data-testid]="'status-radio-compromised'"
          >
            Successful
          </mat-radio-button>
          <mat-radio-button
            class="status-radio-button"
            [value]="objective_status.UNABLE_TO_COMPROMISE"
            [attr.data-testid]="'status-radio-unable'"
          >
            Unsuccessful
          </mat-radio-button>
        </mat-radio-group>
        <quill-editor
          class="evidence-quill-editor"
          formControlName="evidence"
          placeholder="Evidence"
          (onEditorCreated)="addQuillImageHandler($event)"
        >
        </quill-editor>
      </mat-dialog-content>
      <mat-dialog-actions>
        <div class="status-error">
          @if (!statusReportForm.controls['status'].valid && triedToSubmit) {
            <mat-error> Status field is required </mat-error>
          }
        </div>
        <div>
          <button mat-raised-button color="primary" type="submit" [disabled]="isSubmitting">
            OK
          </button>
          <button mat-button matDialogClose>Cancel</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
}
