<cybexer-widget-body class="widget-header">
  @if (productKey) {
    <mat-card>
      <mat-card-title>Product key details</mat-card-title>
      <mat-card-content>
        <div class="isa-details-box">
          <div class="row"><span class="label">Licensed to</span> {{ productKey.licensedTo }}</div>
          <div class="row">
            <span class="label">Valid until</span> {{ productKey.validUntil | isaDate: 'medium' }}
          </div>
          <div class="row">
            <span class="label">Number of users</span> {{ productKey.numberOfUsers }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  }
  @if (productKeyForm) {
    <form role="form" [formGroup]="productKeyForm" (ngSubmit)="save()" class="isa-form">
      <mat-form-field class="form-field-full textarea">
        <mat-label>Enter product key</mat-label>
        <textarea
          formControlName="productKey"
          matInput
          [cdkTextareaAutosize]
          [cdkAutosizeMinRows]="2"
          tabindex="-1"
        ></textarea>
        @if (
          productKeyForm.controls['productKey'].touched &&
          productKeyForm.controls['productKey'].hasError('required')
        ) {
          <mat-error> Please enter a product key </mat-error>
        }
      </mat-form-field>
      <button
        mat-raised-button
        type="submit"
        [disabled]="processing || productKeyForm.invalid"
        color="primary"
      >
        Save
      </button>
    </form>
  }
</cybexer-widget-body>
