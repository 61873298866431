<div class="chart-widget-wrapper">
  <cybexer-widget-header
    [inline]="true"
    [title]="isIndividualAssessment ? 'User Scoring' : 'Team Scoring'"
  >
    <mat-slide-toggle
      class="horizontal-view-toggle"
      [ngModel]="(filter$ | async).horizontalView"
      (ngModelChange)="filterStateService.setFilter('horizontalView', $event)"
    >
      Horizontal view
    </mat-slide-toggle>
    <isa-filtered-select
      [options]="teams"
      [multiple]="true"
      [preselectedOptions]="(filter$ | async).teams"
      [optionKey]="'customName'"
      [optionValue]="'id'"
      [label]="isIndividualAssessment ? 'Show users' : 'Show teams'"
      (selectionChange)="filterStateService.setFilter('teams', $event)"
    ></isa-filtered-select>
    <isa-exercise-duration-slider
      ngDefaultControl
      [ngModel]="filter$ | async"
      (ngModelChange)="
        filterStateService.setFilters({
          sliderDate: $event.sliderDate,
          timePeriod: $event.selectedTimePeriod
        })
      "
    ></isa-exercise-duration-slider>
  </cybexer-widget-header>

  <div class="chart-wrapper">
    @if (selectedTeamsData.length) {
      <isa-scoring-charts [scoringData]="selectedTeamsData" [isCTF]="isCTF"></isa-scoring-charts>
    }
    @if (!selectedTeamsData.length && !loading) {
      <cybexer-no-data></cybexer-no-data>
    }
    @if (loading) {
      <cybexer-progress-spinner></cybexer-progress-spinner>
    }
  </div>
</div>
