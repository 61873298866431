<div
  #infiniteScrollContainer
  class="scoring-log"
  infiniteScroll
  isaInfiniteScrollLoadMoreItemsOnStartup
  [items]="scoringLogData"
  [scrollWindow]="false"
  [infiniteScrollContainer]="infiniteScrollContainer"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="200"
  [infiniteScrollDisabled]="infiniteScrollDisabled"
  (scrolled)="onScroll()"
>
  <cybexer-widget-header [title]="'Scoring Log'">
    <isa-filtered-select
      [options]="teams"
      [preselectedOption]="(filter$ | async).team"
      [optionKey]="'customName'"
      [optionValue]="'id'"
      [defaultOptionKey]="'All teams'"
      [disabled]="!isAdmin && userRole === ROLES.BLUE"
      [label]="exercise?.isIndividualAssessment ? 'User' : 'Team'"
      (selectionChange)="filterStateService.setFilter('team', $event)"
    ></isa-filtered-select>
    <mat-form-field>
      <mat-label>Category</mat-label>
      <mat-select
        ngModel
        multiple
        ngDefaultControl
        (ngModelChange)="filterStateService.setFilter('scoreCategories', $event)"
        [ngModel]="(filter$ | async).scoreCategories"
        class="category-filter-dropdown"
      >
        @for (category of categories; track category) {
          <mat-option [value]="category">
            {{ category | isaReplaceUnderScores }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <isa-date-picker
      ngDefaultControl
      placeholder="Start date"
      type="datetime"
      [ngModel]="(filter$ | async).startDate"
      (ngModelChange)="filterStateService.setFilter('startDate', $event)"
    ></isa-date-picker>
    <isa-date-picker
      ngDefaultControl
      placeholder="End date"
      type="datetime"
      [ngModel]="(filter$ | async).endDate"
      (ngModelChange)="filterStateService.setFilter('endDate', $event)"
    ></isa-date-picker>
    <div class="spacer"></div>
    <button mat-mini-fab (click)="refresh()">
      <i class="fas fa-sync" aria-hidden="true"></i>
    </button>
  </cybexer-widget-header>
  <cybexer-simple-table
    [tableData]="scoringLogData"
    [columns]="scoringLogColumns"
  ></cybexer-simple-table>
  <div>
    @if (!scoringLogData.length && !loading) {
      <cybexer-no-data></cybexer-no-data>
    }
    @if (loading) {
      <cybexer-progress-spinner></cybexer-progress-spinner>
    }
  </div>
</div>
