<div class="upload-image-dialog">
  <h2 matDialogTitle>Upload image file</h2>
  @if (!loading) {
    <div>
      <mat-dialog-content>
        <form>
          <label class="image-label" for="imageInput">
            <div class="attachment-btn"><i class="fas fa-paperclip" aria-hidden="true"></i></div>
            @if (image && error == null) {
              <i class="fas fa-check approved-icon" aria-hidden="true"></i>
            }
            @if (image == null) {
              <span> Choose a file</span>
            }
            @if (image) {
              <span>{{ image.name }} ({{ image.size | isaFileSize }})</span>
            }
          </label>
          <input
            (change)="setFile($event)"
            class="image-input"
            id="imageInput"
            type="file"
            [accept]="getFileInputAcceptAttr()"
          />
          @if (error) {
            <div class="row error-container">
              <mat-error class="disable-uppercase">{{ error }}</mat-error>
            </div>
          }
          <div class="restriction-info">
            <div>Max file size: {{ maxFileSize | isaFileSize: 0 }}</div>
            <div>
              Supported types:
              <span class="disable-uppercase"> {{ getFileInputAcceptAttr() }}</span>
            </div>
          </div>
        </form>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button matDialogClose>Cancel</button>
      </mat-dialog-actions>
    </div>
  }
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</div>
