<cybexer-widget-header [title]="'Attack Reports'">
  <mat-slide-toggle
    class="switch-container"
    labelPosition="after"
    [ngModel]="(filter$ | async).pendingConfirmationOnly"
    (ngModelChange)="filterStateService.setFilter('pendingConfirmationOnly', $event)"
    >Pending Confirmation Only
  </mat-slide-toggle>
  <isa-filtered-select
    [options]="teams"
    [preselectedOption]="(filter$ | async).team"
    [optionKey]="'customName'"
    [optionValue]="'id'"
    [defaultOptionKey]="'All ' + (exercise?.isIndividualAssessment ? 'Users' : 'Teams')"
    [label]="exercise?.isIndividualAssessment ? 'User' : 'Team'"
    (selectionChange)="filterStateService.setFilter('team', $event)"
  ></isa-filtered-select>
  <mat-form-field>
    <mat-label><i class="fas fa-filter" aria-hidden="true"></i> Filter</mat-label>
    <input id="textFilter" autocomplete="off" matInput [formControl]="textFilterFormControl" />
  </mat-form-field>
  <cybexer-confirm-action
    [disabled]="!selection.selected.length"
    data-testid="confirm-selected"
    *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
  >
    <button
      (click)="multipleConfirmAction(CONFIRMATION_STATUS.CONFIRMED)"
      [disabled]="!selection.selected.length"
      mat-raised-button
    >
      <i class="fas fa-check-circle mat-button-fa" aria-hidden="true"></i> Confirm selected
    </button>
  </cybexer-confirm-action>
  <cybexer-confirm-action
    [disabled]="!selection.selected.length"
    [attr.data-testid]="'deny-selected'"
    *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
  >
    <button
      (click)="multipleConfirmAction(CONFIRMATION_STATUS.DENIED)"
      [disabled]="!selection.selected.length"
      mat-raised-button
    >
      <i class="fas fa-exclamation-circle mat-button-fa" aria-hidden="true"></i> Deny selected
    </button>
  </cybexer-confirm-action>
</cybexer-widget-header>
<div class="attack-report cybexer-table-wrapper">
  <table mat-table matSort [dataSource]="dataSource" class="isa-table">
    <ng-container [matColumnDef]="COLUMNS.SELECT">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleSelectAll() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disabled]="getFilteredUnconfirmedReports().length === 0"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let report" (click)="$event.stopPropagation()">
        @if (report.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION) {
          <mat-checkbox
            (change)="$event ? selection.toggle(report) : null"
            [checked]="selection.isSelected(report)"
          >
          </mat-checkbox>
        }
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TIMESTAMP">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
      <td mat-cell *matCellDef="let report">{{ report.timestamp | isaDate: 'medium' }}</td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.OBJECTIVE_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Objective</th>
      <td mat-cell class="disable-uppercase" data-testid="objective-cell" *matCellDef="let report">
        @if (report.objectiveReportsCount > 1) {
          <i
            class="far fa-clone report-count"
            [matTooltip]="'Reports for this objective: ' + report.objectiveReportsCount"
            [matBadge]="report.objectiveReportsCount?.toString()"
            matBadgeColor="warn"
          ></i>
        }
        {{ report.objectiveName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.OBJECTIVE_CONTAINER_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Target Check/Group</th>
      <td mat-cell class="disable-uppercase" *matCellDef="let report">
        {{ report.objectiveContainerName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.CAMPAIGN_PHASE_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Campaign Phase</th>
      <td mat-cell class="disable-uppercase" *matCellDef="let report">
        {{ report.campaignPhaseName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TEAM_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
      <td mat-cell *matCellDef="let report">{{ report.teamId | isaTeamName }}</td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        @if ((filter$ | async).pendingConfirmationOnly) {
          <i
            class="fas fa-filter status-filter-icon"
            aria-hidden="true"
            [matTooltip]="'Filter: Unconfirmed'"
          ></i>
        }
        Status
      </th>
      <td mat-cell *matCellDef="let report">{{ report.status | isaReplaceUnderScores }}</td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.WHITE_TEAM_MEMBER">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>White Team Member</th>
      <td mat-cell *matCellDef="let report">
        @if (report.whiteTeamMember && report.whiteTeamMember !== AUTO_CONFIRM_USERNAME) {
          <i class="fas fa-user" aria-hidden="true"></i>
        }
        @if (report.whiteTeamMember && report.whiteTeamMember === AUTO_CONFIRM_USERNAME) {
          <i class="fas fa-desktop" aria-hidden="true"></i>
        }
        {{ report.whiteTeamMember }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="clickable-row"
      *matRowDef="let report; columns: displayedColumns"
      (click)="openAttackReportConfirmDialog(report)"
      [attr.data-testid]="report.objectiveId"
      [hidden]="dataSource.loading$ | async"
    ></tr>
  </table>

  @if ((dataSource.filteredCount$ | async) === 0 && (dataSource.loading$ | async) === false) {
    <cybexer-no-data></cybexer-no-data>
  }
  @if (dataSource.loading$ | async) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
  <mat-paginator
    [length]="dataSource.filteredCount$ | async"
    [pageSizeOptions]="pageSizes"
    showFirstLastButtons
  ></mat-paginator>
</div>
