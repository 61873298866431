import { BaseModel } from '../shared/base.model';
import { ReportData, ReportFilterResult } from './report-data.model';

export class AttackReport extends BaseModel implements ReportData {
  reportId: string;
  teamId: string;
  teamName: string;
  objectiveContainerId: string;
  objectiveContainerName: string;
  objectiveId: string;
  objectiveName: string;
  objectiveStatus: string;
  campaignPhaseName: string;
  whiteTeamMember?: string;
  status: string;
  timestamp: Date;
  feedback?: string;
  objectiveReportsCount: number;
  username: string;

  constructor(data?) {
    super(data);
  }
}

export class AttackReportConfirmation extends BaseModel {
  teamId: string;
  objectiveContainerId: string;
  objectiveId: string;
  reportId: string;
  status: string;
  feedback?: string;

  constructor(data?) {
    super(data);
  }
}

export class AttackReportFilterResult extends ReportFilterResult<AttackReport> {
  constructor(data) {
    super(data, AttackReport);
  }
}
