@if (event && showSubjectFields) {
  <mat-form-field class="full-width" [attr.data-testid]="'subjectTypeField'">
    <mat-label>Subject type</mat-label>
    <input disabled matInput [value]="event?.subjectTypeDisplayText" />
  </mat-form-field>
  <mat-form-field class="full-width" [attr.data-testid]="'subjectNameField'">
    <mat-label>Subject name</mat-label>
    <input disabled matInput [value]="event?.subjectName" />
  </mat-form-field>
}

<mat-form-field class="full-width" [attr.data-testid]="'eventTypeField'">
  <mat-label>Event type</mat-label>
  <mat-select [(value)]="selectedEventType" [disabled]="!!event?.id">
    @for (eventType of eventTypes; track eventType) {
      <mat-option [value]="eventType">
        {{ eventType | isaIntegrationEventTypeName }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>

@if (selectedEventType) {
  <mat-form-field class="full-width" [attr.data-testid]="'integrationField'">
    <mat-label>Integration</mat-label>
    <mat-select [(value)]="selectedIntegrationId" required>
      @for (integration of filteredIntegrations; track integration) {
        <mat-option [value]="integration.id">
          {{ integration.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
}

@switch (selectedEventType) {
  @case (IntegrationEventType.TARGET_STATUS_CHANGE) {
    <isa-target-status-change-event-form
      [subjectId]="subjectId"
      [subjectType]="subjectType"
      [event]="event"
      [triggerSubmit]="submit$"
      (onChange)="onSubmit($event)"
    ></isa-target-status-change-event-form>
  }
  @default {
    <isa-zero-configuration-event-form
      [subjectId]="subjectId"
      [subjectType]="subjectType"
      [event]="event"
      [eventType]="selectedEventType"
      [triggerSubmit]="submit$"
      (onChange)="onSubmit($event)"
    ></isa-zero-configuration-event-form>
  }
}

<button
  type="submit"
  class="isa-button-confirm"
  mat-raised-button
  color="primary"
  (click)="submit$.next(null)"
>
  {{ event?.id ? 'Update' : 'Add' }}
</button>

@if (event?.id) {
  <cybexer-confirm-action>
    <button class="isa-button-delete" mat-raised-button (click)="deleteEvent()">Delete</button>
  </cybexer-confirm-action>
}
