<cybexer-widget-header [title]="'Users'" [inline]="true">
  <div class="spacer"></div>
  <button (click)="openUserCreationDialog()" mat-raised-button color="primary">
    <i class="fas fa-plus-circle" aria-hidden="true"></i>New user
  </button>
</cybexer-widget-header>
<isa-users-table [usersChanged$]="usersChanged$">
  <ng-template let-user="user" #singleUserActions>
    <button
      class="user-edit-button"
      mat-raised-button
      [routerLink]="'/app/admin/users/' + user.username"
      [disabled]="user.isSetForRemoval"
    >
      <i class="fas fa-edit" aria-hidden="true"></i>Edit
    </button>
    <button
      class="user-delete-button"
      mat-raised-button
      color="primary"
      (click)="openUserDeletionDialog(user.username)"
      [disabled]="user.isSetForRemoval || isCurrentUser(user.username)"
    >
      <i class="fas fa-trash-alt" aria-hidden="true"></i
      >{{ user.isSetForRemoval ? 'Deleting...' : 'Delete' }}
    </button>
  </ng-template>
</isa-users-table>
