<mat-expansion-panel
  class="exercise-group"
  [togglePosition]="'before'"
  [disabled]="!group.name"
  #groupPanel
>
  <mat-expansion-panel-header
    class="exercise-group-header"
    expandedHeight="84px"
    collapsedHeight="84px"
  >
    <mat-panel-title class="exercise-group-title">
      @if (!group.name) {
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input [formControl]="nameForm" matInput type="text" />
        </mat-form-field>
      } @else {
        {{ group.name }}
      }
    </mat-panel-title>
    <mat-panel-description class="exercise-group-description">
      {{ group.exercises.length }} exercises
    </mat-panel-description>
    <div class="exercise-group-actions">
      @if (!group.name) {
        <div class="creation-actions">
          <button mat-raised-button class="save-button" color="primary" (click)="createItem()">
            SAVE
          </button>
          <button mat-raised-button color="primary" (click)="removeItem()">
            <i class="fas fa-trash delete-icon" aria-hidden="true"></i>
            Delete
          </button>
        </div>
      } @else {
        <button
          mat-button
          [disabled]="
            group?.exercises.length === 0 || (!hasStatus('NOT_STARTED') && !hasStatus('STOPPED'))
          "
          (click)="openExerciseConfirmDialog(group, 'STARTEX', $event)"
        >
          <i class="fas fa-play" aria-hidden="true"></i>
          Start
        </button>
        <button
          mat-button
          [disabled]="group?.exercises.length === 0 || !hasStatus('RUNNING')"
          (click)="openExerciseConfirmDialog(group, 'ENDEX', $event)"
        >
          <i class="fas fa-stop" aria-hidden="true"></i>
          Stop
        </button>
        @if (isEditing) {
          <button color="primary" mat-raised-button (click)="editGroup()">SAVE</button>
          <button mat-raised-button (click)="cancelEdit()">CANCEL</button>
        } @else {
          <button mat-button (click)="editGroup()">
            <i class="fas fa-edit" aria-hidden="true"></i>
            Edit
          </button>
          <button
            mat-icon-button
            class="fas fa-ellipsis-h"
            [matMenuTriggerFor]="moreActionsMenu"
            (click)="$event.stopPropagation()"
          >
            <i aria-hidden="true"></i>
          </button>
          <mat-menu
            xPosition="before"
            #moreActionsMenu="matMenu"
            [overlapTrigger]="false"
            class="more-actions-menu"
          >
            <button
              mat-menu-item
              *isaRequirePermission="EXERCISE_PERMISSIONS.ADMIN"
              data-testid="delete-exercise-group-btn"
              (click)="deleteGroup()"
            >
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
              Delete
            </button>
          </mat-menu>
        }
      }
    </div>
  </mat-expansion-panel-header>

  @if (group.exercises) {
    <cybexer-grid [itemSize]="'small'">
      @for (exercise of group.exercises; track exercise) {
        <div class="container">
          <isa-exercise-card
            class="grouped-exercise"
            [exercise]="exercise"
            [isInGroup]="true"
            [findExerciseOverviewById]="findExerciseOverviewById"
          ></isa-exercise-card>
          @if (isEditing) {
            <div class="overlay" (click)="removeExerciseFromGroup(exercise.id)">
              <div class="circle">
                <div class="dash"></div>
              </div>
            </div>
          }
        </div>
      }
    </cybexer-grid>
  }
</mat-expansion-panel>
