import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { merge, Observable, of, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, skip, tap } from 'rxjs/operators';
import { IncidentReportsDatasource } from '../../../datasources';
import {
  BlueTeam,
  Exercise,
  IncidentReportConfirmation,
  IncidentReportsData,
  ReportFilter,
} from '../../../models';
import {
  AuthenticationService,
  ExerciseService,
  IncidentReportsService,
  IntervalService,
  PreferenceService,
} from '../../../services';
import {
  CONFIRMATION_STATUS,
  DEFAULT_PAGE_SIZE,
  EXERCISE_PERMISSIONS,
  FilterStateModel,
  FilterStateService,
  PAGE_SIZES,
  RoleCode,
  ROLES,
} from '../../../shared';
import { IncidentReportConfirmDialogComponent } from './incident-report-confirm-dialog/incident-report-confirm-dialog.component';

@UntilDestroy()
@Component({
  selector: 'isa-incident-reports',
  templateUrl: './incident-reports.component.html',
  styleUrls: ['./incident-reports.component.scss'],
})
export class IncidentReportsComponent implements OnInit, AfterViewInit {
  static DEFAULT_OFFSET = 0;

  filter$: Observable<Partial<FilterStateModel>>;

  exercise: Exercise;
  teams: BlueTeam[] = [];
  incidentConfirmDialogRef: MatDialogRef<IncidentReportConfirmDialogComponent>;
  textFilterFormControl = new UntypedFormControl();
  ROLES = ROLES;
  userRole: RoleCode;
  CONFIRMATION_STATUS = CONFIRMATION_STATUS;
  isAdmin: boolean;
  readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;

  readonly COLUMNS = {
    SELECT: 'select',
    TIMESTAMP: 'timestamp',
    TARGET_NAME: 'targetName',
    TARGET_CHECK_NAME: 'targetCheckName',
    INCIDENT_TYPE: 'incidentType',
    TEAM_NAME: 'teamName',
    STATUS: 'status',
    WHITE_TEAM_MEMBER: 'whiteTeamMember',
  };
  readonly displayedColumns = [
    this.COLUMNS.SELECT,
    this.COLUMNS.TIMESTAMP,
    this.COLUMNS.TARGET_NAME,
    this.COLUMNS.TARGET_CHECK_NAME,
    this.COLUMNS.INCIDENT_TYPE,
    this.COLUMNS.TEAM_NAME,
    this.COLUMNS.STATUS,
    this.COLUMNS.WHITE_TEAM_MEMBER,
  ];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  dataSource: IncidentReportsDatasource;
  selection = new SelectionModel<IncidentReportsData>(true, []);

  constructor(
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private incidentReportsService: IncidentReportsService,
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService,
    private preferenceService: PreferenceService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.isAdmin = this.authenticationService.currentUser.isAdmin;
    this.filter$ = this.filterStateService.filter$('team', 'pendingConfirmationOnly');
    this.paginator.pageSize =
      this.preferenceService.currentPreferences.defaultListSize || DEFAULT_PAGE_SIZE;

    this.dataSource = new IncidentReportsDatasource(this.incidentReportsService);
    this.dataSource.loading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      if (!loading) {
        const selectedReportIds = this.selection.selected.map((it) => it.reportId);
        this.clearSelection();
        this.selectAll(selectedReportIds);
      }
    });

    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        tap((exercise: Exercise) => {
          this.exercise = exercise;
          this.teams = exercise.blueTeams;
          this.userRole = this.authenticationService.getRole(exercise.id);
          if (!this.isAdmin) {
            this.isAdmin = this.authenticationService.currentUser.isGamenetAdmin(this.exercise.id);
          }
        }),
        switchMap((exercise: Exercise) => {
          if (this.userRole === ROLES.BLUE) {
            this.filterStateService.setFilter('pendingConfirmationOnly', false);
            return this.exerciseService.getUserBlueTeam(exercise.id).pipe(untilDestroyed(this));
          }

          return of(null);
        }),
        tap((userBlueTeamId) => {
          if (userBlueTeamId != null) {
            this.filterStateService.setFilterIfEmptyOrDefault('team', userBlueTeamId);
          }

          this.loadReports();
        }),
        switchMap(() => this.intervalService.getWidgetRefreshInterval()),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.loadReportsSilently();
      });

    // TODO: first call to filter needs to be investigated
    this.filter$.pipe(skip(1), untilDestroyed(this)).subscribe(() => {
      this.paginator.firstPage();
      this.loadReports();
    });
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => this.paginator.firstPage());

    this.textFilterFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.clearSelection();
          this.paginator.firstPage();
          this.loadReports();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.clearSelection();
          this.loadReports();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private loadReports(loadSilently: boolean = false): void {
    if (this.exercise) {
      this.dataSource.loadReports(this.exercise.id, this._createReportFilter(), loadSilently);
    }
  }

  private loadReportsSilently(): void {
    this.loadReports(true);
  }

  private _createReportFilter(): ReportFilter {
    const limit = this.paginator.pageSize || DEFAULT_PAGE_SIZE;
    const { team, pendingConfirmationOnly } = this.filterStateService.snapshot();
    const sortDirection = this.sort.direction;
    const sortOrder = sortDirection != null && !!sortDirection ? sortDirection : null;

    return new ReportFilter({
      offset:
        this.paginator.pageIndex == null
          ? IncidentReportsComponent.DEFAULT_OFFSET
          : this.paginator.pageIndex * limit,
      limit: limit,
      unconfirmedOnlyFilter: pendingConfirmationOnly,
      teamIdFilter: team,
      textFilter: this.textFilterFormControl.value,
      sortColumn: sortOrder ? this.sort.active : null,
      sortOrder: sortOrder,
    });
  }

  openIncidentReportConfirmDialog(incidentReportData: IncidentReportsData): void {
    this.incidentConfirmDialogRef = this.dialog.open(IncidentReportConfirmDialogComponent, {
      disableClose: false,
      data: {
        incidentReportsData: incidentReportData,
        exercise: this.exercise,
      },
    });

    this.incidentConfirmDialogRef
      .afterClosed()
      .subscribe((incidentConfirmation: IncidentReportConfirmation) => {
        if (incidentConfirmation) {
          this.clearSelection();
          this.loadReports();
        }
        this.incidentConfirmDialogRef = null;
      });
  }

  toggleSelectAll(): void {
    this.isAllSelected() ? this.clearSelection() : this.selectAll();
  }

  private selectAll(reportIdFilter: string[] = null): void {
    const selectedReportIds = this.selection.selected.map((it) => it.reportId);
    this.getFilteredUnconfirmedReports()
      .filter((it) => selectedReportIds.indexOf(it.reportId) === -1)
      .filter((it) => reportIdFilter == null || reportIdFilter.indexOf(it.reportId) !== -1)
      .forEach((reportData) => this.selection.select(reportData));
  }

  clearSelection(): void {
    this.selection.clear();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.getFilteredUnconfirmedReports().length;

    return numSelected === numRows;
  }

  getFilteredUnconfirmedReports(): IncidentReportsData[] {
    return this.dataSource.data.filter(
      (it) => it.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION
    );
  }

  multipleConfirmAction(status) {
    if (status) {
      const incidentReportConfirmations: IncidentReportConfirmation[] = this.selection.selected.map(
        (reportData) => {
          return new IncidentReportConfirmation({
            reportId: reportData.reportId,
            status: status,
          });
        }
      );
      this.incidentReportsService
        .createMultipleIncidentReportConfirmations(this.exercise.id, incidentReportConfirmations)
        .pipe(
          finalize(() => this.clearSelection()),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.loadReports();
          this.notificationsService.success(
            `Reports have been ${status === CONFIRMATION_STATUS.CONFIRMED ? 'confirmed' : 'denied'}`
          );
        });
    }
  }

  get pageSizes(): number[] {
    return PAGE_SIZES;
  }
}
