export const TEMPLATE =
  '' +
  '<h1>I. Event Overview</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>II. Adversary assessment </h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>III. Impact on operations</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>IV. Actions Taken and Results</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>V. Status</h1>' +
  '<p><br></p><p><br></p>' +
  '<h1>VI. Any other Business (AOB)</h1>' +
  '<p><br></p><p><br></p>';
