import { BaseModel } from '../shared/base.model';
import { ReportData, ReportFilterResult } from './report-data.model';

export class IncidentReportsData extends BaseModel implements ReportData {
  reportId: string;
  teamId: string;
  teamName: string;
  targetId: string;
  targetName: string;
  targetCheckId?: string;
  targetCheckName?: string;
  incidentType: string;
  status: string;
  timestamp: Date;
  blueTeamMember: string;
  whiteTeamMember?: string;
  details: string;
  feedback?: string;

  constructor(data?) {
    super(data);
  }
}

export class IncidentReportConfirmation extends BaseModel {
  reportId: string;
  status: string;
  feedback?: string;

  constructor(data?) {
    super(data);
  }
}

export class IncidentReportFilterResult extends ReportFilterResult<IncidentReportsData> {
  constructor(data) {
    super(data, IncidentReportsData);
  }
}
