<div class="incident-report">
  <cybexer-widget-header [title]="'Incident Report'"></cybexer-widget-header>
  <cybexer-widget-body>
    <form [formGroup]="reportForm" class="isa-form">
      <div class="cards-container">
        <section class="fill-height">
          <mat-card class="target-checks-card">
            <mat-card-title>Targets</mat-card-title>
            <mat-card-content>
              <mat-form-field class="form-field-full">
                <mat-label>Team</mat-label>
                <mat-select formControlName="team" required="true">
                  @for (team of teams; track team) {
                    <mat-option [value]="team.id">
                      {{ team.customName }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field class="form-field-full">
                <mat-label>
                  <i class="fas fa-filter" aria-hidden="true"></i> Filter targets
                </mat-label>
                <input
                  autocomplete="off"
                  matInput
                  [formControl]="filterText"
                  (keydown.enter)="$event.preventDefault()"
                />
              </mat-form-field>
              <div class="target-checks">
                <mat-selection-list
                  dense
                  formControlName="targetChecks"
                  class="target-check-selection"
                >
                  @for (target of targets?.targets; track target) {
                    @if (!isTargetFilteredOut(target)) {
                      <mat-list-item
                        (click)="toggleTargetChecks(target)"
                        [ngClass]="{ 'selected-list-item': isAllChecksSelected(target) }"
                      >
                        <div class="target-check-group" matListItemLine>
                          <div
                            [matTooltip]="target.hostname"
                            [matTooltipShowDelay]="200"
                            matTooltipPosition="left"
                            class="disable-uppercase target-check-group-content"
                          >
                            {{ target.name }}
                          </div>
                          <mat-checkbox
                            (click)="
                              $event.preventDefault();
                              $event.stopPropagation();
                              toggleTargetChecks(target)
                            "
                            [checked]="isAllChecksSelected(target)"
                            [indeterminate]="
                              !isAllChecksSelected(target) && isAnyCheckSelected(target)
                            "
                          ></mat-checkbox>
                        </div>
                      </mat-list-item>
                    }
                    @for (targetCheck of target.targetChecks; track targetCheck) {
                      <mat-list-option
                        [value]="targetCheck"
                        [ngClass]="{
                          'selected-list-item':
                            reportForm.controls['targetChecks'].value &&
                            reportForm.controls['targetChecks'].value.includes(targetCheck),
                          hidden: isTargetFilteredOut(target)
                        }"
                      >
                        <div matListItemLine class="disable-uppercase target-check-name">
                          {{ targetCheck.name }}
                        </div>
                      </mat-list-option>
                    }
                  }
                </mat-selection-list>
              </div>
            </mat-card-content>
            <mat-card-footer>
              @if (
                reportForm.controls['targetChecks'].value &&
                reportForm.controls['targetChecks'].value.length
              ) {
                <div class="footer-padding">
                  {{ reportForm.controls['targetChecks'].value.length }} target check
                  @if (reportForm.controls['targetChecks'].value.length > 1) {
                    <span>s</span>
                  }
                  selected
                </div>
              }
              @if (
                reportForm.controls['targetChecks'].touched &&
                reportForm.controls['targetChecks'].hasError('required')
              ) {
                <mat-error> Please select target check </mat-error>
              }
            </mat-card-footer>
          </mat-card>
        </section>
        <section class="fill-height">
          <mat-card class="incident-types-card">
            <mat-card-title>Incidents</mat-card-title>
            <mat-card-content class="incident-types">
              <mat-list dense>
                @for (incidentType of incidentTypes; track incidentType) {
                  <mat-list-item
                    matRipple
                    (click)="selectIncidentType(incidentType.name)"
                    [ngClass]="{
                      'selected-list-item':
                        reportForm.controls['incidentType'].value === incidentType.name
                    }"
                    [matTooltip]="incidentType.description"
                    [matTooltipShowDelay]="200"
                    matTooltipPosition="left"
                  >
                    <div matListItemLine class="incident-type-item">
                      <div>
                        {{ incidentType.name | isaIncidentTypeName }}
                      </div>
                      <i class="fas fa-tags" aria-hidden="true"></i>
                    </div>
                  </mat-list-item>
                }
              </mat-list>
              @if (loading) {
                <cybexer-progress-spinner></cybexer-progress-spinner>
              }
            </mat-card-content>
            <mat-card-footer>
              @if (
                reportForm.controls['incidentType'].touched &&
                reportForm.controls['incidentType'].hasError('required')
              ) {
                <mat-error class="footer-padding"> Please select incident type </mat-error>
              }
            </mat-card-footer>
          </mat-card>
          <mat-card class="details">
            <mat-card-title>Details</mat-card-title>
            <mat-card-content>
              <quill-editor
                formControlName="details"
                [styles]="{ height: '420px' }"
                [modules]="{ toolbar: false }"
              >
              </quill-editor>
            </mat-card-content>
          </mat-card>
        </section>
      </div>
      <cybexer-confirm-action [disabled]="processing">
        <button
          class="submit-button"
          mat-raised-button
          type="submit"
          color="primary"
          [disabled]="processing"
          (click)="onSubmit(reportForm)"
        >
          Submit
        </button>
      </cybexer-confirm-action>
    </form>
  </cybexer-widget-body>
</div>
