<div class="login">
  @if (customLogoSrc) {
    <div>
      <img [src]="customLogoSrc | isaSafeUrl" style="height: 250px; margin: 30px 0 -40px" />
    </div>
  }
  <mat-card id="login-card">
    <form role="form" [formGroup]="loginForm" (ngSubmit)="login(loginForm)" class="isa-form">
      <mat-card-content>
        <mat-form-field class="form-field-full">
          <mat-label>Name</mat-label>
          <input matInput formControlName="username" />
          @if (loginForm.controls['username'].hasError('required')) {
            <mat-error> Login name is required </mat-error>
          }
        </mat-form-field>
        <mat-form-field class="form-field-full">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password" />
          @if (loginForm.controls['password'].hasError('required')) {
            <mat-error> Password is required </mat-error>
          }
        </mat-form-field>
        @if (wrongCredentials) {
          <mat-card-subtitle class="wrong-credentials"> Wrong credentials </mat-card-subtitle>
        }
        <div class="actions">
          <button mat-raised-button type="submit" [disabled]="loadingData" color="primary">
            login
          </button>
        </div>
      </mat-card-content>
      <mat-card-footer>
        @if (loadingData) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
      </mat-card-footer>
    </form>
  </mat-card>
  @if (observerSettings && observerSettings.isEnabled && enabledObserverViews.length > 0) {
    <button mat-button [matMenuTriggerFor]="observerMenu" data-testid="observerMenuBtn">
      <i class="fas fa-eye" aria-hidden="true"></i> Observe
    </button>
    <mat-menu #observerMenu [attr.data-testid]="'observerMenu'">
      @for (view of enabledObserverViews; track view) {
        <a mat-menu-item [routerLink]="view.path">
          {{ view.name }}
        </a>
      }
    </mat-menu>
  }
</div>
