import { Component, OnInit } from '@angular/core';
import { Column, ColumnType } from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, switchMap } from 'rxjs';
import { debounceTime, filter, finalize, tap } from 'rxjs/operators';
import {
  AdvancedScoringLogData,
  AdvancedScoringLogItem,
  BlueTeam,
  Exercise,
  Meta,
} from '../../../models';
import { DatePipe, FixedNumberPipe, ReplaceUnderscoresPipe, TeamNamePipe } from '../../../pipes';
import { AdvancedScoringLogService, ExerciseService } from '../../../services';
import { DateUtil, FilterStateModel, FilterStateService } from '../../../shared';

@UntilDestroy()
@Component({
  selector: 'isa-advanced-scoring-log',
  templateUrl: './advanced-scoring-log.component.html',
  styleUrls: ['./advanced-scoring-log.component.scss'],
})
export class AdvancedScoringLogComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;

  exercise: Exercise;
  teams: BlueTeam[] = [];
  categories: string[] = [];
  advancedScoringLogItems: AdvancedScoringLogItem[] = [];
  advancedScoringLogColumns: Column[];
  metaData: Meta;
  timestamp: Date;
  infiniteScrollDisabled: boolean;
  loading: boolean;

  private page = 1;
  private readonly size = 25;

  constructor(
    private exerciseService: ExerciseService,
    private advancedScoringLogService: AdvancedScoringLogService,
    private dateUtil: DateUtil,
    private datePipe: DatePipe,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$(
      'team',
      'scoreCategories',
      'startDate',
      'endDate'
    );

    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        tap((exercise) => {
          this.infiniteScrollDisabled = true; // disable infiniteScroll during update
          this.exercise = exercise;
          this.teams = exercise.blueTeams;
          this.page = 1;
          this.advancedScoringLogItems = [];
          this.timestamp = null;
          this.infiniteScrollDisabled = false;
        }),
        switchMap((exercise: Exercise) => this.exerciseService.getExerciseScoreTypes(exercise.id)),
        tap((scoreTypes) => (this.categories = scoreTypes)),
        switchMap(() => this.filter$),
        debounceTime(50),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.refresh();
      });

    this.initTable();
  }

  private initTable(): void {
    this.advancedScoringLogColumns = [
      new Column({
        name: 'Time',
        dataKey: 'timestamp',
        pipeFn: (data) => this.datePipe.transform(data, 'medium'),
      }),
      new Column({
        name: 'Category',
        dataKey: 'category',
        pipeFn: (data) => new ReplaceUnderscoresPipe().transform(data),
      }),
      new Column({
        name: this.exercise?.isIndividualAssessment ? 'User' : 'Team',
        dataKey: 'teamId',
        pipeFn: (data) => new TeamNamePipe(this.exerciseService).transform(data),
      }),
      new Column({
        name: 'TARGET OBJECT TYPE',
        dataKey: 'targetObjectType',
        pipeFn: (data) => new ReplaceUnderscoresPipe().transform(data),
      }),
      new Column({
        name: 'TARGET OBJECT NAME',
        dataKey: 'targetObjectName',
      }),
      new Column({
        name: 'Score',
        dataKey: 'score',
        position: 'right',
        type: ColumnType.SCORE,
        pipeFn: (data) => new FixedNumberPipe().transform(data, 4),
      }),
    ];
  }

  onScroll(): void {
    this.infiniteScrollDisabled = true;
    this.page++;
    this.getLog();
  }

  getLog(): void {
    this.loading = true;

    const { team, scoreCategories, startDate, endDate } = this.filterStateService.snapshot();

    this.advancedScoringLogService
      .getLog(
        this.exercise.id,
        team,
        scoreCategories,
        startDate,
        endDate,
        this.timestamp,
        this.page,
        this.size
      )
      .pipe(
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe((res: AdvancedScoringLogData) => {
        if (!this.timestamp) {
          this.timestamp = new Date(res.meta.timestamp);
        }
        this.advancedScoringLogItems = this.advancedScoringLogItems.concat(res.data);
        this.metaData = res.meta;
        this.infiniteScrollDisabled = res.data.length === 0;
      });
  }

  refresh(): void {
    if (!this.exercise) return;

    this.page = 1;
    this.advancedScoringLogItems = [];
    this.infiniteScrollDisabled = false;
    this.timestamp = null;
    this.getLog();
  }
}
