<div class="image-removal-dialog">
  <!-- todo can be replaced with commons confirm-dialog -->
  <h2 matDialogTitle>Are you sure you want to delete this image?</h2>
  <mat-dialog-actions align="end">
    <div>
      <button (click)="confirmDeleteImage()" mat-raised-button color="primary">Delete</button>
      <button mat-button matDialogClose>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
