@if (subjectId && subjectType) {
  <mat-label id="add-event">
    <button
      mat-raised-button
      class="isa-icon-button"
      color="primary"
      (click)="addEvent()"
      data-testid="addEventBtn"
    >
      <i class="fas fa-plus-circle" aria-hidden="true"></i>Add event
    </button>
  </mat-label>
}
@if (events$ | async; as events) {
  <mat-accordion multi="false" class="isa-content-accordion">
    @if (events.length) {
      @for (event of events; track eventsTrackByFn($index, event)) {
        <mat-expansion-panel
          (opened)="setExpandedEvent(event.id)"
          [expanded]="expandedEventId === event.id"
        >
          <mat-expansion-panel-header>
            <span class="disable-uppercase"
              >{{ event.type | isaIntegrationEventTypeName }} event
              @if (!subjectId && event.subjectName) {
                <span class="disable-uppercase"> ({{ event.subjectName }})</span>
              }
            </span>
          </mat-expansion-panel-header>
          <isa-integration-event-form
            [allowedEventTypes]="eventTypes"
            [event]="event"
            [subjectType]="subjectType"
            [subjectId]="subjectId"
            [showSubjectFields]="!subjectId"
            (onSave)="onSave($event, false)"
            (onDelete)="refreshEvents()"
          ></isa-integration-event-form>
        </mat-expansion-panel>
      }
    } @else if (!isNewEventOpen) {
      <cybexer-no-data message="No events found"></cybexer-no-data>
    }
    @if (isNewEventOpen) {
      <mat-expansion-panel
        [expanded]="expandedEventId == null"
        [attr.data-testid]="'newEventPanel'"
      >
        <mat-expansion-panel-header> <span>New event</span> </mat-expansion-panel-header>
        <isa-integration-event-form
          [allowedEventTypes]="eventTypes"
          [subjectType]="subjectType"
          [subjectId]="subjectId"
          (onSave)="onSave($event, true)"
        ></isa-integration-event-form>
        <button mat-button (click)="isNewEventOpen = false" data-testid="cancelNewEventBtn">
          Cancel
        </button>
      </mat-expansion-panel>
    }
    @if (!isNewEventOpen) {}
  </mat-accordion>
} @else {
  <cybexer-progress-spinner></cybexer-progress-spinner>
}
