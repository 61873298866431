import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Exercise, ExerciseOverview, ExerciseStatus, ExerciseType, User } from '../../../models';
import { ExerciseResetSteps } from '../../../models/gamenet/exercise.model';
import { AuthenticationService, ExerciseService, IntervalService } from '../../../services';
import { EXERCISE_PERMISSIONS } from '../../../shared';
import { ExerciseGroup, ExerciseGroupSave } from '../../../models/gamenet/exercise-group.model';
import { ExerciseGroupService } from '../../../services/gamenet/exercise-group.service';

@UntilDestroy()
@Component({
  selector: 'isa-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
})
export class ExerciseComponent implements OnInit {
  exerciseGroups: ExerciseGroup[];
  ungroupedExercises: ExerciseOverview[];
  currentExercise: Exercise;
  selectedGroupIndex: number;
  exerciseResetSteps: Map<String, ExerciseResetSteps> = new Map();
  currentUser: User;
  exerciseResetStatusSubscriptions: Map<String, Subscription> = new Map();
  processingExerciseIds: string[] = [];
  ExerciseType = ExerciseType;
  loading = true;
  showOnlyRunning = false;
  searchText: string;
  isModuleGroupEditingEnabled = false;
  isGroupCreationInProgress = false;
  readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;
  readonly ExerciseStatus = ExerciseStatus;

  constructor(
    private exerciseService: ExerciseService,
    private exerciseGroupService: ExerciseGroupService,
    private authenticationService: AuthenticationService,
    private intervalService: IntervalService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUser;
    this.intervalService
      .getWidgetRefreshInterval()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.isModuleGroupEditingEnabled) return;
        this.getExerciseList();
      });
    this.exerciseService.exerciseListChanged.pipe(untilDestroyed(this)).subscribe(() => {
      this.getExerciseList();
    });

    this.exerciseService.activeExercise.pipe(untilDestroyed(this)).subscribe((ex) => {
      this.currentExercise = ex;
    });
  }

  private getExerciseList(ungroupedOnly = false, compatibleExercises?: ExerciseOverview[]) {
    if (this.isGroupCreationInProgress) {
      return;
    }
    this.exerciseGroupService
      .getGroups()
      .pipe(untilDestroyed(this))
      .subscribe((exerciseGroups) => {
        this.ungroupedExercises = exerciseGroups[0].exercises;
        if (ungroupedOnly) {
          const groupExercises = this.exerciseGroups[this.selectedGroupIndex].exercises;
          this.exerciseGroups[this.selectedGroupIndex].exercises = [];
          this.ungroupedExercises.push(...groupExercises, ...compatibleExercises);
        } else {
          // First in list is always ungrouped
          exerciseGroups.splice(0, 1);
          this.exerciseGroups = exerciseGroups;
          this.exerciseGroups
            .flatMap((exerciseGroup) => exerciseGroup.exercises)
            .forEach((exercise) => {
              const exerciseResetStepsForEx = this.exerciseResetSteps.get(exercise.id) || null;
              if (!exercise.isResetInProgress && exerciseResetStepsForEx) {
                this.clearResetData(exercise.id);
              }
            });
        }
        this.loading = false;
      });
  }

  reloadScripts(exerciseId: string): void {
    const indexOf = this.processingExerciseIds.push(exerciseId) - 1;
    this.exerciseService
      .reloadScripts(exerciseId)
      .pipe(finalize(() => this.processingExerciseIds.splice(indexOf, 1)))
      .subscribe(() => {
        this.notificationsService.success('Scripts have been reloaded');
      });
  }

  findExerciseOverviewById(id: string): ExerciseOverview {
    return this.ungroupedExercises.find((ex) => ex.id === id);
  }

  createExerciseGroup() {
    this.isGroupCreationInProgress = true;
    this.exerciseGroups.push(new ExerciseGroup({ name: undefined, exercises: [] }));
  }

  removeExerciseGroup(index: number) {
    this.exerciseGroups.splice(index, 1);
    this.isModuleGroupEditingEnabled = false;
    this.isGroupCreationInProgress = false;
    this.getExerciseList();
  }

  removeExerciseFromGroup(exerciseId: string) {
    const group = this.exerciseGroups[this.selectedGroupIndex];

    if (group.exercises.length === 1) {
      const compatibleExercises = this.ungroupedExercises;
      this.getExerciseList(true, compatibleExercises);
    } else {
      const exerciseIndex = group.exercises.findIndex((exercise) => exercise.id === exerciseId);
      const exercise = group.exercises[exerciseIndex];
      group.exercises.splice(exerciseIndex, 1);
      this.ungroupedExercises.push(exercise);
    }
  }

  saveExerciseGroup(index: number) {
    this.exerciseGroupService
      .createGroup(this.exerciseGroupToSaveData(this.exerciseGroups[index]))
      .pipe(untilDestroyed(this))
      .subscribe(
        (group) => {
          this.exerciseGroups[index] = group;
          this.isGroupCreationInProgress = false;
          this.notificationsService.success('Group created successfully');
        },
        () => {
          this.notificationsService.error('Group created failed');
        }
      );
  }

  addExerciseToGroup(index: number) {
    const groupExercises = this.exerciseGroups[this.selectedGroupIndex].exercises;
    const exercise = this.ungroupedExercises[index];
    const selectedExerciseId = this.ungroupedExercises[index].id;
    this.ungroupedExercises.splice(index, 1);

    if (groupExercises.length === 0) {
      this.getCompatibleExercises(selectedExerciseId);
    }

    this.exerciseGroups[this.selectedGroupIndex].exercises.push(exercise);
  }

  toggleModuleGroupEditing(i: number, cancelled = false) {
    this.selectedGroupIndex = i;
    this.isModuleGroupEditingEnabled = !this.isModuleGroupEditingEnabled;
    if (cancelled) {
      this.getExerciseList();
      return;
    }
    if (!this.isModuleGroupEditingEnabled) {
      const updatedGroup = this.exerciseGroupToSaveData(this.exerciseGroups[i]);
      this.exerciseGroupService
        .updateGroup(updatedGroup)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (group) => {
            this.ungroupedExercises = group.exercises;
            this.notificationsService.success('Group update succeeded');
          },
          error: () => {
            this.notificationsService.error('Group update failed');
          },
        });
      return;
    }
    if (this.exerciseGroups[i].exercises.length > 0) {
      this.getCompatibleExercises(this.exerciseGroups[i].exercises[0].id);
    }
  }

  get filteredGroups(): ExerciseGroup[] {
    if (!this.searchText && !this.showOnlyRunning) return this.exerciseGroups;

    return this.exerciseGroups.filter((group) => {
      const filteredExercises = group.exercises.filter((exercise) => {
        const matchesSearchText = this.searchText
          ? exercise.name.toLowerCase().includes(this.searchText.toLowerCase())
          : true;
        const matchesRunning = !this.showOnlyRunning || exercise.status === ExerciseStatus.RUNNING;
        return matchesSearchText && matchesRunning;
      });

      group.exercises = filteredExercises;

      const matchesGroupName = this.searchText
        ? group.name.toLowerCase().includes(this.searchText.toLowerCase())
        : true;

      return (
        (filteredExercises.length > 0 || matchesGroupName) &&
        (!this.showOnlyRunning || filteredExercises.length > 0)
      );
    });
  }

  get filteredExercises(): ExerciseOverview[] {
    if (!this.searchText && !this.showOnlyRunning) return this.ungroupedExercises;

    return this.ungroupedExercises.filter((exercise) => {
      let matches = true;
      if (this.searchText) {
        matches =
          exercise.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          (exercise.description != null &&
            exercise.description.toLowerCase().includes(this.searchText.toLowerCase()));
      }

      if (this.showOnlyRunning) {
        matches &&= exercise.status === ExerciseStatus.RUNNING;
      }
      return matches;
    });
  }

  private clearResetData(exerciseId: string) {
    this.exerciseResetSteps.delete(exerciseId);
    this.unsubscribeFromStatusEvents(exerciseId);
  }

  private unsubscribeFromStatusEvents(exerciseId: string) {
    if (this.exerciseResetStatusSubscriptions.has(exerciseId)) {
      this.exerciseResetStatusSubscriptions.get(exerciseId).unsubscribe();
      this.exerciseResetStatusSubscriptions.delete(exerciseId);
    }
  }

  private exerciseGroupToSaveData(group: ExerciseGroup): ExerciseGroupSave {
    return new ExerciseGroupSave({
      id: group.id,
      name: group.name,
      exercises: group.exercises.map((exercise) => exercise.id),
    });
  }

  private getCompatibleExercises(exerciseId: string) {
    this.loading = true;
    this.exerciseService
      .getCompatibleExercises(exerciseId)
      .pipe(untilDestroyed(this))
      .subscribe((exercises) => {
        this.ungroupedExercises = this.ungroupedExercises.filter((exercise) => {
          return exercises.indexOf(exercise.id) !== -1;
        });
        this.loading = false;
      });
  }
}
