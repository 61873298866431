<div class="isa-layout">
  @if (!isHeaderHidden) {
    <header class="isa-header">
      <mat-toolbar class="isa-header">
        <div class="sidebar-toggle-container">
          <button mat-icon-button (click)="toggleNav()">
            <i class="fas fa-bars fa-lg" aria-hidden="true"></i>
          </button>
          <div><span class="isa-title">ISA</span></div>
        </div>
        <div class="isa-header-center">
          @if (isRouteInModule('gamenet')) {
            <span class="isa-current-exercise">
              <i class="fas fa-tasks mat-button-fa" aria-hidden="true"></i>
              {{ currentExercise ? currentExercise.name : 'No module selected' }}
            </span>
          }
        </div>
        <div>
          @if (hasExternalRedirectUrl()) {
            <button mat-button (click)="redirectToExternalUrl()" [matTooltip]="'Go back'">
              <i class="fas fa-home mat-button-fa" aria-hidden="true"></i> Home
            </button>
          }
          <button
            class="profile-menu-button"
            mat-button
            [matMenuTriggerFor]="userMenu"
            [disabled]="currentUser?.loginOrigin === LOGIN_ORIGIN.EXTERNAL"
            data-testid="userMenuButton"
          >
            <i class="fas fa-user mat-button-fa" aria-hidden="true"></i>{{ currentUser?.fullName }}
          </button>
          @if (settings && settings?.gamenetEnabled) {
            <button
              class="notifications-btn"
              mat-icon-button
              (click)="toggleNotifications()"
              [matTooltip]="'Toggle notifications'"
            >
              @if (newNotifications > 0) {
                <div class="new-notifications">
                  {{
                    newNotifications >= NOTIFICATIONS_PAGE_SIZE
                      ? NOTIFICATIONS_PAGE_SIZE
                      : newNotifications
                  }}
                  @if (newNotifications >= NOTIFICATIONS_PAGE_SIZE) {
                    <span>+</span>
                  }
                </div>
              }
              <i class="fas fa-bell mat-button-fa" aria-hidden="true"></i>
            </button>
          }
        </div>
      </mat-toolbar>
      <mat-menu xPosition="before" #userMenu="matMenu" class="user-menu" [overlapTrigger]="false">
        @if (!isObserverUser()) {
          <button mat-menu-item [routerLink]="'/app/user/settings'">
            <i class="fas fa-cog" aria-hidden="true"></i> Settings
          </button>
        }
        @if (isLocalUser()) {
          <button mat-menu-item (click)="openPasswordChangeDialog()" data-testid="changePassword">
            <i class="fas fa-key" aria-hidden="true"></i> Change password
          </button>
        }
        <button mat-menu-item (click)="logout()">
          <i class="fas fa-sign-out-alt" aria-hidden="true"></i> Logout
        </button>
      </mat-menu>
      <mat-menu xPosition="before" #exerciseMenu="matMenu" [overlapTrigger]="false">
        @for (exercise of exercises; track exercise) {
          <button mat-menu-item (click)="selectExercise(exercise.id)">
            <i class="fas fa-cogs" aria-hidden="true"></i>{{ exercise.name }}
          </button>
        }
      </mat-menu>
    </header>
  }
  <mat-sidenav-container
    class="isa-sidenav"
    [ngClass]="{ 'sidenav-without-header': isHeaderHidden }"
  >
    <mat-sidenav #nav [opened]="!isSideNavHidden" mode="side" class="isa-sidenav-container">
      <mat-accordion multi="true">
        <isa-navigation-menu></isa-navigation-menu>
      </mat-accordion>
    </mat-sidenav>
    <mat-sidenav
      #notifications
      class="notifications-sidenav"
      mode="side"
      position="end"
      [opened]="isNotificationsContentShown"
    >
      @if (settings?.gamenetEnabled) {
        <isa-notifications
          [exerciseId]="currentExercise?.id"
          (newNotifications)="newNotificationsHandler($event)"
        ></isa-notifications>
      }
    </mat-sidenav>
    <main class="isa-content">
      <div
        #contentBody
        class="isa-content-body"
        [ngClass]="{
          'content-without-header': isHeaderHidden && !isFooterHidden,
          'content-without-footer': isFooterHidden && !isHeaderHidden,
          'content-without-header-and-footer': isHeaderHidden && isFooterHidden
        }"
      >
        @if (!loading) {
          <router-outlet></router-outlet>
        }
        @if (loading) {
          <cybexer-progress-spinner></cybexer-progress-spinner>
        }
      </div>
      @if (!isFooterHidden) {
        <isa-footer></isa-footer>
      }
    </main>
  </mat-sidenav-container>
</div>
