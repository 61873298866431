<div id="user-dialog">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="isa-form">
    <mat-dialog-content>
      <div class="isa-mat-form-row">
        <mat-form-field class="full-width">
          <mat-label>Username</mat-label>
          <input type="text" matInput formControlName="username" />
          @if (form.get('username').invalid) {
            <mat-error>Must be provided</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="isa-mat-form-row">
        <mat-form-field class="full-width">
          <mat-label>Full name</mat-label>
          <input type="text" matInput formControlName="fullName" />
          @if (form.get('fullName').invalid) {
            <mat-error>Must be provided</mat-error>
          }
        </mat-form-field>
      </div>
      <isa-password-fields [formGroup]="form.get('passwordGroup')"></isa-password-fields>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="submit" mat-raised-button color="primary">Save</button>
      <button mat-button matDialogClose>Cancel</button>
    </mat-dialog-actions>
  </form>
</div>
