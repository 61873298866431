<div class="incident-report-confirm-dialog">
  <h2 matDialogTitle>Blue Incident Report</h2>
  <mat-dialog-content class="incident-report-confirm-dialog-content">
    <div class="isa-details-box">
      <div class="row">
        <span class="label">Details</span>
        @if (reportDetailsLoaded) {
          <quill-editor
            [ngModel]="reportDetails"
            [readOnly]="true"
            [modules]="{ toolbar: false }"
          ></quill-editor>
        }
        @if (!reportDetailsLoaded) {
          <cybexer-progress-spinner></cybexer-progress-spinner>
        }
      </div>
      <div class="row">
        <span class="label">Time</span> {{ incidentReportsData?.timestamp | isaDate: 'medium' }}
      </div>
      <div class="row">
        <span class="label">Team</span> {{ incidentReportsData?.teamId | isaTeamName }}
      </div>
      <div class="row">
        <span class="label">Team member</span> {{ incidentReportsData?.blueTeamMember }}
      </div>
      <div class="row">
        <span class="label">Target</span>
        {{ incidentReportsData?.targetId | isaTargetName: exercise }}
      </div>
      <div class="row">
        <span class="label">Incident Type</span>
        {{ incidentReportsData?.incidentType | isaIncidentTypeName }}
      </div>
    </div>
    @if (showFeedback()) {
      <mat-form-field class="full-width textarea feedback">
        <mat-label>Feedback</mat-label>
        <textarea
          [disabled]="
            !hasPermissionToConfirmOrDeny ||
            incidentReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION
          "
          [(ngModel)]="feedback"
          matInput
          [cdkTextareaAutosize]
          tabindex="-1"
        ></textarea>
      </mat-form-field>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    @if (
      hasPermissionToConfirmOrDeny &&
      incidentReportsData?.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION
    ) {
      <cybexer-confirm-action>
        <button
          class="isa-button-confirm"
          (click)="submitConfirmation(CONFIRMATION_STATUS.CONFIRMED)"
          mat-raised-button
          color="primary"
        >
          <i class="fas fa-check-circle mat-button-fa" aria-hidden="true"></i>Confirm
        </button>
      </cybexer-confirm-action>
      <cybexer-confirm-action>
        <button
          class="isa-button-deny"
          (click)="submitConfirmation(CONFIRMATION_STATUS.DENIED)"
          mat-raised-button
        >
          <i class="fas fa-exclamation-circle mat-button-fa" aria-hidden="true"></i>Deny
        </button>
      </cybexer-confirm-action>
    }
    <button mat-button matDialogClose>Cancel</button>
  </mat-dialog-actions>
</div>
