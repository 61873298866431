import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { filter, finalize } from 'rxjs/operators';
import {
  BlueTeam,
  Exercise,
  IncidentReport,
  IncidentType,
  Target,
  TargetCheck,
  Targets,
} from '../../../models';
import {
  AuthenticationService,
  ExerciseService,
  IncidentService,
  IncidentTypeService,
  TargetService,
} from '../../../services/';
import { FormUtil, RoleCode, ROLES } from '../../../shared';
import { TEMPLATE } from './incident-report-template';

@UntilDestroy()
@Component({
  selector: 'isa-incident-report',
  templateUrl: './incident-report.component.html',
  styleUrls: ['./incident-report.component.scss'],
})
export class IncidentReportComponent implements OnInit {
  private static TEAM = 'team';
  private static TEAM_ID = 'teamId';
  private static TARGET_CHECKS = 'targetChecks';
  private static TARGET_CHECK_ID = 'targetCheckId';
  private static INCIDENT_TYPE = 'incidentType';
  private static DETAILS = 'details';

  exercise: Exercise;
  targets: Targets;
  template: string;
  incidentTypes: IncidentType[];
  teams: BlueTeam[] = [];
  reportForm: UntypedFormGroup;
  filterText: UntypedFormControl;
  ROLES = ROLES;
  userRole: RoleCode;
  userBlueTeamId: string;
  processing: boolean;
  loading = true;
  isAdmin: boolean;

  private teamIdQueryParam: string;
  private targetCheckIdQueryParam: string;
  private targetStatusQueryParam: string;

  constructor(
    private exerciseService: ExerciseService,
    private targetService: TargetService,
    private incidentService: IncidentService,
    private incidentTypeService: IncidentTypeService,
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.isAdmin = authenticationService.currentUser.isAdmin;
  }

  ngOnInit() {
    this.filterText = new UntypedFormControl('', Validators.maxLength(100));
    this.reportForm = new UntypedFormGroup({
      team: new UntypedFormControl('', Validators.required),
      targetChecks: new UntypedFormControl([''], Validators.required),
      incidentType: new UntypedFormControl('', Validators.required),
      details: new UntypedFormControl(''),
    });

    this.reportForm.controls[IncidentReportComponent.TEAM].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((teamId: string) => {
        this.reportForm.controls[IncidentReportComponent.TARGET_CHECKS].reset();
        if (teamId && this.exercise) {
          this.targetService
            .getTargets(this.exercise.id, teamId)
            .pipe(untilDestroyed(this))
            .subscribe((targets: Targets) => {
              this.targets = targets;
              this.checkForTargetCheckQueryParam(targets);
            });
        } else {
          this.targets = null;
        }
      });

    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.userRole = this.authenticationService.getRole(exercise.id);
        this.exercise = exercise;
        this.targets = null;
        this.reportForm.reset();
        this.teams = exercise.blueTeams;
        if (!this.isAdmin) {
          this.isAdmin = this.authenticationService.currentUser.isGamenetAdmin(this.exercise.id);
        }
        if (this.userRole === this.ROLES.BLUE) {
          this.exerciseService
            .getUserBlueTeam(exercise.id)
            .pipe(untilDestroyed(this))
            .subscribe((userBlueTeamId) => {
              this.userBlueTeamId = userBlueTeamId;
              this.reportForm.controls[IncidentReportComponent.TEAM].setValue(userBlueTeamId);
            });
          if (!this.isAdmin) {
            this.reportForm.controls[IncidentReportComponent.TEAM].disable();
          }
        }

        this.template = exercise.incidentReportTemplate || TEMPLATE;
        this.reportForm.controls[IncidentReportComponent.DETAILS].setValue(this.template);

        this.incidentTypeService
          .getIncidentTypes(exercise.id)
          .pipe(untilDestroyed(this))
          .subscribe((types) => {
            this.incidentTypes = types;
            this.loading = false;
          });

        this.getReportFormValuesFromQueryParameters();
      });
  }

  private checkForTargetCheckQueryParam(targets: Targets) {
    if (this.targetCheckIdQueryParam) {
      const matchedTargetCheck = targets.targets
        .map((target) => target.targetChecks)
        .reduce((resultArray, currentArray) => resultArray.concat(currentArray), [])
        .filter((targetCheck) => targetCheck.id === this.targetCheckIdQueryParam);

      this.reportForm.controls[IncidentReportComponent.TARGET_CHECKS].patchValue(
        matchedTargetCheck
      );
    }
  }

  private getReportFormValuesFromQueryParameters() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.teamIdQueryParam = params[IncidentReportComponent.TEAM_ID]
        ? params[IncidentReportComponent.TEAM_ID]
        : undefined;
      this.targetCheckIdQueryParam = params[IncidentReportComponent.TARGET_CHECK_ID]
        ? params[IncidentReportComponent.TARGET_CHECK_ID]
        : undefined;
      this.targetStatusQueryParam = params[IncidentReportComponent.INCIDENT_TYPE]
        ? params[IncidentReportComponent.INCIDENT_TYPE]
        : undefined;

      this.assignTeamIdAndTargetStatusToReportForm();
    });
  }

  private assignTeamIdAndTargetStatusToReportForm() {
    if (this.teamIdQueryParam) {
      this.reportForm.get(IncidentReportComponent.TEAM).patchValue(this.teamIdQueryParam);
      if (this.targetStatusQueryParam) {
        this.reportForm.controls[IncidentReportComponent.INCIDENT_TYPE].patchValue(
          this.targetStatusQueryParam
        );
      }
    }
  }

  onSubmit(form: UntypedFormGroup): void {
    if (!form.valid) {
      FormUtil.markFormControlAsTouchedRecursively(form);
      return;
    }
    this.processing = true;
    const incidentReports: IncidentReport[] = form.value.targetChecks.map((targetCheck) => {
      const target = this.targets.targets.find((t) => t.targetChecks.indexOf(targetCheck) !== -1);
      return new IncidentReport({
        targetId: target.id,
        targetCheckId: targetCheck.id,
        networkSegmentId: target.networkSegmentId,
        incidentType: form.value.incidentType,
        username: this.authenticationService.currentUser.username,
        details: form.value.details ? form.value.details : undefined,
      });
    });
    const teamId =
      !this.isAdmin && this.userRole === this.ROLES.BLUE ? this.userBlueTeamId : form.value.team;
    this.incidentService
      .createIncidentReport(this.exercise.id, teamId, incidentReports)
      .pipe(
        finalize(() => (this.processing = false)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.userRole === this.ROLES.BLUE) {
          this.reportForm.controls[IncidentReportComponent.TARGET_CHECKS].reset();
          this.reportForm.controls[IncidentReportComponent.INCIDENT_TYPE].reset();
          this.reportForm.controls[IncidentReportComponent.DETAILS].setValue(this.template);
        } else {
          this.reportForm.reset();
          this.reportForm.controls[IncidentReportComponent.DETAILS].setValue(this.template);
        }
        this.notificationsService.success('Blue incident report has been sent');
      });
  }

  selectIncidentType(type: string): void {
    this.reportForm.controls[IncidentReportComponent.INCIDENT_TYPE].setValue(type);
  }

  isTargetFilteredOut(target: Target): boolean {
    if (this.filterText.value) {
      const filteredOutTargets = this.targets.targets.filter(
        (t) => !t.name.toLowerCase().includes(this.filterText.value.toLowerCase())
      );
      return filteredOutTargets.includes(target);
    }
  }

  toggleTargetChecks = (target: Target) => {
    const targetChecks: TargetCheck[] = this.reportForm.controls[
      IncidentReportComponent.TARGET_CHECKS
    ].value
      ? this.reportForm.controls[IncidentReportComponent.TARGET_CHECKS].value
      : [];
    if (this.isAllChecksSelected(target)) {
      target.targetChecks.forEach((targetCheck) => {
        const indexOf = targetChecks.indexOf(targetCheck);
        if (indexOf !== -1) {
          targetChecks.splice(indexOf, 1);
        }
      });
      this.reportForm.controls[IncidentReportComponent.TARGET_CHECKS].setValue(targetChecks);
    } else {
      const setOfNewTargetChecks = new Set(targetChecks.concat(target.targetChecks));
      this.reportForm.controls[IncidentReportComponent.TARGET_CHECKS].setValue(
        Array.from(setOfNewTargetChecks)
      );
    }
  };

  isAllChecksSelected = (target: Target) => {
    const targetChecks: TargetCheck[] = this.reportForm.controls[
      IncidentReportComponent.TARGET_CHECKS
    ].value
      ? this.reportForm.controls[IncidentReportComponent.TARGET_CHECKS].value
      : [];
    return target.targetChecks.every((targetCheck) => targetChecks.indexOf(targetCheck) !== -1);
  };

  isAnyCheckSelected = (target: Target) => {
    const targetChecks: TargetCheck[] = this.reportForm.controls[
      IncidentReportComponent.TARGET_CHECKS
    ].value
      ? this.reportForm.controls[IncidentReportComponent.TARGET_CHECKS].value
      : [];
    return target.targetChecks.some((targetCheck) => targetChecks.indexOf(targetCheck) !== -1);
  };
}
