<div class="scoring-log-details-dialog">
  <h2 matDialogTitle>Details</h2>
  <mat-dialog-content class="coring-log-details-dialog-content">
    <div class="isa-details-box">
      <div class="row">
        <span class="label">Category</span>
        {{ scoringLogItem?.category }}
      </div>
      <div class="row">
        <span class="label">Time</span>
        {{ scoringLogItem?.timestamp | isaDate: 'medium' }}
      </div>
      <div class="row">
        <span class="label">Team</span>
        {{ scoringLogItem?.teamId | isaTeamName }}
      </div>
      <div class="row">
        <span class="label">Score</span>
        {{ scoringLogItem?.score | isaFixedNumber }}
      </div>
      @if (specialMessageDetails) {
        <div class="row details-container">
          <span class="label">Details</span>
          <mat-form-field class="full-width">
            <textarea
              matInput
              [cdkTextareaAutosize]
              tabindex="-1"
              disabled
              [value]="specialMessageDetails.details"
            ></textarea>
          </mat-form-field>
        </div>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>Close</button>
  </mat-dialog-actions>
</div>
