import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AttackReport,
  AttackReportConfirmation,
  AttackReportFilterResult,
  ReportDetails,
  ReportFilter,
} from '../../models';
import { ServiceUtil } from '../../shared';
import { ReportsService } from './reports.service';

@Injectable()
export class AttackReportsService implements ReportsService<AttackReport> {
  constructor(private http: HttpClient) {}

  getData(exerciseId: string, filter: ReportFilter): Observable<AttackReportFilterResult> {
    return this.http
      .get<AttackReportFilterResult>(`api/widget/attack-reports/exercise/${exerciseId}`, {
        params: ServiceUtil.createHttpParamsFromObj(filter),
      })
      .pipe(map((data) => new AttackReportFilterResult(data)));
  }

  getReportDetails(exerciseId: string, reportId: string): Observable<ReportDetails> {
    return this.http
      .get<ReportDetails>(
        `api/widget/attack-reports/exercise/${exerciseId}/report/${reportId}/details`
      )
      .pipe(map((data) => new ReportDetails(data)));
  }

  createAttackReportConfirmation(
    exerciseId: string,
    confirmation: AttackReportConfirmation
  ): Observable<boolean> {
    return this.http
      .post(`api/widget/attack-reports/exercise/${exerciseId}/confirmation`, confirmation)
      .pipe(map(() => true));
  }

  createMultipleAttackReportConfirmations(
    exerciseId: string,
    confirmations: AttackReportConfirmation[]
  ): Observable<boolean> {
    return this.http
      .post(`api/widget/attack-reports/exercise/${exerciseId}/confirmation/batch`, confirmations)
      .pipe(map(() => true));
  }
}
