<cybexer-widget-header title="Settings" [inline]="true">
  <div class="spacer"></div>
  <span class="date">last modified: {{ lastModified | isaDate: 'short' }}</span>
</cybexer-widget-header>
<cybexer-widget-body>
  @if (settingsForm) {
    <form
      role="form"
      [formGroup]="settingsForm"
      (ngSubmit)="save()"
      class="isa-form global-settings"
    >
      <mat-card>
        <mat-card-title>Modules</mat-card-title>
        <mat-card-content>
          <mat-slide-toggle
            class="switch-container"
            formControlName="gamenetEnabled"
            labelPosition="after"
          >
            Show cyber range
          </mat-slide-toggle>
        </mat-card-content>
      </mat-card>
      @if (settingsForm.controls.gamenetEnabled.value) {
        <mat-card>
          <mat-card-title>Observer mode</mat-card-title>
          <mat-card-content>
            <mat-slide-toggle
              class="switch-container"
              formControlName="observerEnabled"
              labelPosition="after"
            >
              Enable
            </mat-slide-toggle>
            @if (settingsForm.controls.observerEnabled.value) {
              <div class="observer-configuration-container" formGroupName="observerSettings">
                <mat-form-field class="form-field-full">
                  <mat-label>Observer key</mat-label>
                  <input autocomplete="off" type="text" matInput formControlName="key" />
                  <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    aria-label="Generate"
                    (click)="resetObserverKey($event)"
                    tabindex="-1"
                  >
                    <i class="fas fa-redo" aria-hidden="true"></i>
                  </button>
                </mat-form-field>
                <mat-form-field class="form-field-full">
                  <mat-label>Primary exercise</mat-label>
                  <mat-select class="observer-exercise-id-field" formControlName="exerciseId">
                    <mat-option [value]="null"></mat-option>
                    @for (exerciseOverview of exerciseOverviews; track exerciseOverview) {
                      <mat-option [value]="exerciseOverview.id">
                        {{ exerciseOverview.name }}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                @if (isDefaultExerciseSelected()) {
                  <mat-selection-list formControlName="enabledViews">
                    @for (view of observableViews; track view) {
                      <mat-list-option checkboxPosition="before" [value]="view.type">
                        <span matLine>{{ view.name }}</span>
                        <span matLine>
                          <span class="disable-uppercase tone-down">
                            {{ urlProtocolHost + view.path }}
                          </span>
                        </span>
                        <i
                          matListItemIcon
                          matRipple
                          class="copy-observable-view fas fa-copy"
                          (click)="$event.stopPropagation(); copyViewUrl(view)"
                          matTooltip="Copy to clipboard"
                        ></i>
                      </mat-list-option>
                    }
                  </mat-selection-list>
                }
              </div>
            }
          </mat-card-content>
        </mat-card>
      }
      <mat-card>
        <mat-card-title>LDAP Configuration</mat-card-title>
        <mat-card-content>
          <mat-slide-toggle class="switch-container" formControlName="ldapEnabled">
            Enable
          </mat-slide-toggle>
          @if (settingsForm.controls.ldapEnabled.value) {
            <div class="ldap-configuration-container" formGroupName="ldapSettings">
              <mat-form-field class="form-field-full">
                <mat-label>Principal</mat-label>
                <input
                  required
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="principal"
                />
              </mat-form-field>
              <mat-form-field class="form-field-full">
                <mat-label>Principal Suffix</mat-label>
                <input
                  required
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="principalSuffix"
                />
              </mat-form-field>
              <mat-form-field class="form-field-full">
                <mat-label>Search Base</mat-label>
                <input
                  required
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="searchBase"
                />
              </mat-form-field>
              <mat-form-field class="form-field-full">
                <mat-label>{{ !this.ldapPasswordRequired ? 'New ' : '' }}Password</mat-label>
                <input
                  [required]="ldapPasswordRequired"
                  autocomplete="off"
                  type="password"
                  matInput
                  formControlName="password"
                />
              </mat-form-field>
              <div class="ldap-url-container">
                <div>
                  <span>Urls</span>
                  <button type="button" mat-icon-button (click)="addLdapUrl()">
                    <i class="fas fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="ldap-url-list" formArrayName="urls">
                  @for (url of ldapUrls.controls; track url; let i = $index) {
                    <mat-form-field class="ldap-url form-field-full">
                      <mat-label>Url {{ i + 1 }}</mat-label>
                      <input
                        required
                        autocomplete="off"
                        type="text"
                        matInput
                        formControlName="{{ i }}"
                      />
                      <button type="button" mat-icon-button matSuffix (click)="removeLdapUrl(i)">
                        <i class="fas fa-times" aria-hidden="true"></i>
                      </button>
                    </mat-form-field>
                  }
                </div>
              </div>
              <div class="ldap-group-mappings">
                <div>
                  <span>Group Mappings</span>
                  <button type="button" mat-icon-button (click)="addLdapGroupMapping()">
                    <i class="fas fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="ldap-group-mapping-list" formArrayName="groupMappings">
                  @for (url of ldapGroupMappings.controls; track url; let i = $index) {
                    <div class="ldap-group-mapping" formGroupName="{{ i }}">
                      <mat-form-field class="ldap-group-mapping-field">
                        <mat-label>Role</mat-label>
                        <input
                          required
                          autocomplete="off"
                          type="text"
                          matInput
                          formControlName="role"
                        />
                      </mat-form-field>
                      <mat-form-field class="ldap-group-mapping-field">
                        <mat-label>Distinguished Name</mat-label>
                        <input
                          required
                          autocomplete="off"
                          type="text"
                          matInput
                          formControlName="distinguishedName"
                        />
                        <button
                          type="button"
                          mat-icon-button
                          matSuffix
                          (click)="removeLdapGroupMapping(i)"
                        >
                          <i class="fas fa-times" aria-hidden="true"></i>
                        </button>
                      </mat-form-field>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </mat-card-content>
      </mat-card>
      @if (settingsForm.get('gamenetEnabled').value) {
        <mat-card>
          <mat-card-title>Cyber Range</mat-card-title>
          <mat-card-content formGroupName="gamenetSettings" class="gamenet-settings">
            <mat-form-field
              class="exercise-interval-field interval-field"
              hintLabel="milliseconds (ms)"
            >
              <mat-label>Module Refresh Interval</mat-label>
              <input
                id="exerciseRefreshInterval"
                type="number"
                min="10000"
                matInput
                formControlName="exerciseRefreshInterval"
                required
              />
            </mat-form-field>
            <mat-form-field class="interval-field" hintLabel="milliseconds (ms)">
              <mat-label>Scoring Timeline Widgets Refresh Interval</mat-label>
              <input
                id="scoringTimelineWidgetRefreshInterval"
                type="number"
                min="10000"
                matInput
                formControlName="scoringTimelineWidgetRefreshInterval"
                required
              />
            </mat-form-field>
            <mat-slide-toggle
              class="switch-container"
              formControlName="soundOnTeamChangeEnabled"
              labelPosition="after"
            >
              Team scoring: enable team order change sound
            </mat-slide-toggle>
            @if (settingsForm.get('gamenetSettings.soundOnTeamChangeEnabled').value) {
              <isa-sound-select
                [selectedSoundId]="settingsForm.get('gamenetSettings.selectedSoundId').value"
                (onSelect)="onSoundSelect($event, 'gamenetSettings.selectedSoundId')"
              ></isa-sound-select>
            }
            <mat-divider></mat-divider>
            <mat-slide-toggle
              class="switch-container"
              formControlName="ctfPodiumFirstPlaceSoundEnabled"
              labelPosition="after"
            >
              CTF podium: enable first place change sound <i class="fas fa-trophy gold"></i>
            </mat-slide-toggle>
            @if (settingsForm.get('gamenetSettings.ctfPodiumFirstPlaceSoundEnabled').value) {
              <isa-sound-select
                [selectedSoundId]="
                  settingsForm.get('gamenetSettings.ctfPodiumFirstPlaceSoundId').value
                "
                [defaultSoundPath]="DefaultSound.BASS_BEEP"
                (onSelect)="onSoundSelect($event, 'gamenetSettings.ctfPodiumFirstPlaceSoundId')"
              ></isa-sound-select>
            }
            <mat-divider></mat-divider>
            <mat-slide-toggle
              class="switch-container"
              formControlName="ctfPodiumSecondThirdPlaceSoundEnabled"
              labelPosition="after"
            >
              CTF podium: enable second and third place change sound
              <i class="fas fa-trophy silver"></i><i class="fas fa-trophy bronze"></i>
            </mat-slide-toggle>
            @if (settingsForm.get('gamenetSettings.ctfPodiumSecondThirdPlaceSoundEnabled').value) {
              <isa-sound-select
                [selectedSoundId]="
                  settingsForm.get('gamenetSettings.ctfPodiumSecondThirdPlaceSoundId').value
                "
                (onSelect)="
                  onSoundSelect($event, 'gamenetSettings.ctfPodiumSecondThirdPlaceSoundId')
                "
              ></isa-sound-select>
            }
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="recalculateAvailabilityData()"
              [disabled]="recalculatingAvailabilityData == null || recalculatingAvailabilityData"
            >
              @if (recalculatingAvailabilityData) {
                <div class="spinner-wrapper">
                  <mat-progress-spinner
                    class="recalculating-spinner"
                    mode="indeterminate"
                    [color]="'accent'"
                    [diameter]="20"
                  ></mat-progress-spinner>
                  <span>Recalculating...</span>
                </div>
              }
              @if (!recalculatingAvailabilityData) {
                Recalculate availability data
              }
            </button>
          </mat-card-content>
        </mat-card>
      }
      @if (settingsForm.get('gamenetEnabled').value) {
        <mat-card>
          <mat-card-title>vLM Configuration</mat-card-title>
          <mat-card-content class="vlm-settings">
            <mat-slide-toggle class="switch-container" formControlName="vlmEnabled"
              >Enable
            </mat-slide-toggle>
            @if (settingsForm.controls.vlmEnabled.value) {
              <div class="vlm-configuration-container" formGroupName="vlmSettings">
                <mat-form-field class="form-field-full vlm-url">
                  <mat-label>Server Url</mat-label>
                  <input
                    required
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="serverUrl"
                  />
                  <mat-hint>Url for Server side communication</mat-hint>
                </mat-form-field>
                <mat-form-field class="form-field-full vlm-url">
                  <mat-label>Client Url</mat-label>
                  <input autocomplete="off" type="text" matInput formControlName="clientUrl" />
                  <mat-hint>Url for Client side communication (Default: Server Url)</mat-hint>
                </mat-form-field>
                <mat-form-field class="form-field-full">
                  <mat-label>Service key</mat-label>
                  <input
                    required
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="serviceKey"
                  />
                </mat-form-field>
                <mat-divider></mat-divider>
                <div class="subcard">
                  <mat-card-subtitle>Native</mat-card-subtitle>
                  <mat-slide-toggle
                    class="switch-container"
                    formControlName="nativeConsoleProxyEnabled"
                  >
                    Enable proxy connection
                  </mat-slide-toggle>
                </div>
                <mat-divider></mat-divider>
                <div class="subcard">
                  <mat-card-subtitle>Guacamole</mat-card-subtitle>
                  <mat-radio-group formControlName="guacamoleTunnelType">
                    @for (
                      tunnelType of GuacamoleTunnelType | keyvalue: disableSort;
                      track tunnelType
                    ) {
                      <mat-radio-button id="{{ tunnelType.value }}" [value]="tunnelType.value">
                        {{ tunnelType.value }} tunnel
                      </mat-radio-button>
                    }
                  </mat-radio-group>
                  <mat-slide-toggle
                    class="switch-container"
                    formControlName="guacamoleTunnelProxyEnabled"
                  >
                    Enable proxy connection
                  </mat-slide-toggle>
                </div>
              </div>
            }
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-title>AI Fabric Configuration</mat-card-title>
          <mat-card-content class="aif-settings">
            <mat-slide-toggle class="switch-container" formControlName="aiFabricEnabled"
              >Enable
            </mat-slide-toggle>
            @if (settingsForm.controls.aiFabricEnabled.value) {
              <div class="aif-configuration-container" formGroupName="aiFabricSettings">
                <mat-form-field class="form-field-full aif-url">
                  <mat-label>Server Url</mat-label>
                  <input
                    required
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="serverUrl"
                  />
                  <mat-hint>Url for Server side communication</mat-hint>
                </mat-form-field>
                <mat-form-field class="form-field-full">
                  <mat-label>Service key</mat-label>
                  <input
                    required
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="serviceKey"
                  />
                </mat-form-field>
              </div>
            }
          </mat-card-content>
        </mat-card>
      }
      <mat-card>
        <mat-card-title>Refresh Intervals</mat-card-title>
        <mat-card-content>
          <mat-form-field hintLabel="milliseconds (ms)" class="interval-field">
            <mat-label>Widget refresh interval</mat-label>
            <input
              id="widgetRefreshInterval"
              type="number"
              min="10000"
              matInput
              formControlName="widgetRefreshInterval"
              required
            />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Application Url</mat-card-title>
        <mat-card-content>
          <mat-form-field class="application-url-field">
            <mat-label>Add application url</mat-label>
            <input id="applicationUrl" type="url" matInput formControlName="applicationUrl" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Front-End Sentry DSN</mat-card-title>
        <mat-card-content>
          <mat-form-field class="front-end-sentry-dsn-field">
            <mat-label>Change the Front-End Sentry DSN</mat-label>
            <input id="frontEndSentryDsn" type="url" matInput formControlName="frontEndSentryDsn" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Actions</mat-card-title>
        <mat-card-content>
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="clearAuthorizationCache()"
          >
            Clear authorization cache
          </button>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>
          API Tokens
          <button class="card-title-button" type="button" mat-raised-button (click)="addApiToken()">
            Generate new
          </button>
        </mat-card-title>
        <mat-card-content class="gapped">
          <ng-container formArrayName="apiTokens">
            @for (
              apiTokenFormGroup of apiTokenFormArray.controls;
              track apiTokenFormGroup;
              let i = $index
            ) {
              <mat-card class="inner-card" [formGroupName]="i">
                <mat-card-title>
                  <span class="date">
                    Created {{ apiTokenFormGroup.get('timestamp').value | isaDate: 'medium' }}
                  </span>
                </mat-card-title>
                <mat-card-content class="api-token-card-content">
                  <div class="row-content">
                    <button type="button" (click)="removeApiToken(i)" matSuffix mat-icon-button>
                      <i class="fas fa-times" aria-hidden="true"></i>
                    </button>
                    <mat-form-field class="api-token-name-field">
                      <mat-label>Name</mat-label>
                      <input type="text" matInput formControlName="name" required />
                      <mat-error>Name is required</mat-error>
                    </mat-form-field>
                    <mat-form-field class="api-token-field">
                      <mat-label>Token</mat-label>
                      <input type="text" matInput readonly formControlName="value" />
                    </mat-form-field>
                  </div>
                  <div class="row-content row-content-right">
                    @if (!isSingingKeyDefined(i)) {
                      <div>
                        <button
                          type="button"
                          class="add-signing-key-btn"
                          mat-button
                          (click)="addSigningKey(i)"
                        >
                          <i class="fas fa-plus" aria-hidden="true"></i> Signing key
                        </button>
                      </div>
                    }
                    @if (isSingingKeyDefined(i)) {
                      <button
                        type="button"
                        class="remove-signing-key-btn"
                        (click)="removeSigningKey(i)"
                        matSuffix
                        mat-icon-button
                      >
                        <i class="fas fa-times" aria-hidden="true"></i>
                      </button>
                      <mat-form-field
                        class="api-token-field"
                        formGroupName="signingKey"
                        matTooltip="Readonly"
                      >
                        <mat-label>Signing key</mat-label>
                        <input type="text" matInput readonly formControlName="value" />
                      </mat-form-field>
                    }
                  </div>
                </mat-card-content>
              </mat-card>
            } @empty {
              <cybexer-no-data></cybexer-no-data>
            }
          </ng-container>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <div>
          <mat-card-title>Default theme</mat-card-title>
          <span></span>
          <mat-radio-group label="Default theme" formControlName="isLightTheme">
            <mat-radio-button
              [checked]="!settingsForm.controls['isLightTheme'].value"
              [value]="false"
            >
              Dark theme
            </mat-radio-button>
            <mat-radio-button
              [checked]="settingsForm.controls['isLightTheme'].value"
              [value]="true"
            >
              Light Theme
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-card>
      <mat-card>
        <mat-card-title>Custom logo</mat-card-title>
        <mat-card-content>
          @if (customLogoSrc) {
            <div class="image-container">
              <i
                class="fas fa-times delete-icon"
                (click)="openImageRemovalDialog(false); $event.stopPropagation()"
              ></i>
              <img [src]="customLogoSrc | isaSafeUrl" />
            </div>
          }
          @if (!customLogoSrc) {
            <button
              type="button"
              mat-raised-button
              color="primary"
              (click)="openUploadImageDialog(false)"
            >
              Upload a custom logo
            </button>
          }
        </mat-card-content>
      </mat-card>
      @if (isWhiteLabelEnabled) {
        <mat-card>
          <mat-card-title>Custom label</mat-card-title>
          <mat-card-content>
            @if (customLabelSrc) {
              <div class="image-container">
                <i
                  class="fas fa-times delete-icon"
                  (click)="openImageRemovalDialog(true); $event.stopPropagation()"
                ></i>
                <img [src]="customLabelSrc | isaSafeUrl" />
              </div>
            }
            @if (!customLabelSrc) {
              <button
                type="button"
                mat-raised-button
                color="primary"
                (click)="openUploadImageDialog(true)"
              >
                Upload a custom label
              </button>
            }
          </mat-card-content>
        </mat-card>
      }
      <mat-card>
        <mat-card-title>Experimental Features</mat-card-title>
        <mat-card-content>
          <mat-slide-toggle
            class="switch-container"
            formControlName="experimentalFeaturesEnabled"
            labelPosition="after"
          >
            Enabled
          </mat-slide-toggle>
        </mat-card-content>
      </mat-card>
      <div class="sticky-form-buttons">
        <button mat-raised-button type="submit" [disabled]="processing" color="primary">
          Save
        </button>
      </div>
    </form>
  }
</cybexer-widget-body>
